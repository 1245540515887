import React, { useState, useMemo, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styles from '../FormFieldStyles.module.scss';
import Icon from 'components/Icon';
import { ReactComponent as PasswordVisible } from 'assets/images/passwordVisible.svg';
import { ReactComponent as PasswordHidden } from 'assets/images/passwordHidden.svg';
import Error from '../Error';
import Tooltip from 'components/Tooltip';

const PasswordField = ({
  name,
  label,
  placeholder,
  required,
  minValue,
  maxValue,
  autoFocus,
  showPassword,
  labelHidden,
  tooltipText,
}) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext();

  const [isContentHidden, setIsContentHidden] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const value = useMemo(
    () =>
      name
        .replace(/\[(\w+)\]/g, '.$1')
        .split('.')
        .reduce((value, index) => value && value[index], values),
    //eslint-disable-next-line
    [name, values]
  );

  useEffect(() => {
    value ? setInputValue(value) : setInputValue('');
    //eslint-disable-next-line
  }, [value]);

  return (
    <div className={styles.field}>
      {!labelHidden && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <span className={styles.requiredSign}>*</span>}
          {tooltipText && <Tooltip label={tooltipText} />}
        </label>
      )}
      <Field
        type={isContentHidden ? 'password' : 'text'}
        placeholder={placeholder}
        name={name}
        required={required}
        autoFocus={autoFocus}
        value={inputValue}
        className={styles.input}
        onChange={(e) => {
          handleChange(e);
          setInputValue(e.target.value);
        }}
        onBlur={(e) => {
          handleBlur(e);
          setFieldValue(name, inputValue);
        }}
      />
      {showPassword && (
        <button
          className={styles.eyeButton}
          type="button"
          onClick={() => setIsContentHidden(!isContentHidden)}
        >
          <Icon
            className={styles.inputEye}
            icon={isContentHidden ? PasswordHidden : PasswordVisible}
          />
        </button>
      )}
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
              minValue,
              maxValue,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default PasswordField;

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  autoFocus: PropTypes.bool,
};
