export const ECW_CAMPAIGN_FORM = '[ECWCampaignForm]';

export const ECW_CREATE_CAMPAIGN_REQUEST = `${ECW_CAMPAIGN_FORM} CREATE_CAMPAIGN_REQUEST`;
export const ECW_CREATE_CAMPAIGN_SUCCESS = `${ECW_CAMPAIGN_FORM} CREATE_CAMPAIGN_SUCCESS`;
export const ECW_CREATE_CAMPAIGN_ERROR = `${ECW_CAMPAIGN_FORM} CREATE_CAMPAIGN_ERROR`;

export const POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST = `${ECW_CAMPAIGN_FORM} POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST`;
export const POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS = `${ECW_CAMPAIGN_FORM} POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS`;
export const POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR = `${ECW_CAMPAIGN_FORM} POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR`;
export const POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST = `${ECW_CAMPAIGN_FORM} POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST`;

export const ECW_ADVERTISER_GALLERY_REQUEST = `${ECW_CAMPAIGN_FORM} ADVERTISER_GALLERY_REQUEST`;
export const ECW_ADVERTISER_GALLERY_SUCCESS = `${ECW_CAMPAIGN_FORM} ADVERTISER_GALLERY_SUCCESS`;
export const ECW_ADVERTISER_GALLERY_ERROR = `${ECW_CAMPAIGN_FORM} ADVERTISER_GALLERY_ERROR`;

export const ADVERTISER_CAMPAIGN_GALLERY_REQUEST = `${ECW_CAMPAIGN_FORM} ADVERTISER_CAMPAIGN_GALLERY_REQUEST`;
export const ADVERTISER_CAMPAIGN_GALLERY_SUCCESS = `${ECW_CAMPAIGN_FORM} ADVERTISER_CAMPAIGN_GALLERY_SUCCESS`;
export const ADVERTISER_CAMPAIGN_GALLERY_ERROR = `${ECW_CAMPAIGN_FORM} ADVERTISER_CAMPAIGN_GALLERY_ERROR`;

export const DELETE_GALLERY_ENTRIES_REQUEST = `${ECW_CAMPAIGN_FORM} DELETE_GALLERY_ENTRIES_REQUEST`;
export const DELETE_GALLERY_ENTRIES_SUCCESS = `${ECW_CAMPAIGN_FORM} DELETE_GALLERY_ENTRIES_SUCCESS`;
export const DELETE_GALLERY_ENTRIES_ERROR = `${ECW_CAMPAIGN_FORM} DELETE_GALLERY_ENTRIES_ERROR`;

export const DRAFT_CAMPAIGN_REQUEST = `${ECW_CAMPAIGN_FORM} DRAFT_CAMPAIGN_REQUEST`;
export const DRAFT_CAMPAIGN_SUCCESS = `${ECW_CAMPAIGN_FORM} DRAFT_CAMPAIGN_SUCCESS`;
export const DRAFT_CAMPAIGN_ERROR = `${ECW_CAMPAIGN_FORM} DRAFT_CAMPAIGN_ERROR`;

export const PUBLISH_CAMPAIGN_REQUEST = `${ECW_CAMPAIGN_FORM} PUBLISH_CAMPAIGN_REQUEST`;
export const PUBLISH_CAMPAIGN_SUCCESS = `${ECW_CAMPAIGN_FORM} PUBLISH_CAMPAIGN_SUCCESS`;
export const PUBLISH_CAMPAIGN_ERROR = `${ECW_CAMPAIGN_FORM} PUBLISH_CAMPAIGN_ERROR`;

export const DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE = `${ECW_CAMPAIGN_FORM} DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE`;
export const PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE = `${ECW_CAMPAIGN_FORM} PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE`;

export const CLEAR_UPLOAD_MODAL = 'CLEAR_UPLOAD_MODAL';

export const FETCH_DUPLICATE_CAMPAIGN_REQUEST = `${ECW_CAMPAIGN_FORM} FETCH_DUPLICATE_CAMPAIGN_REQUEST`;
export const FETCH_DUPLICATE_CAMPAIGN_SUCCESS = `${ECW_CAMPAIGN_FORM} FETCH_DUPLICATE_CAMPAIGN_SUCCESS`;

export const VERIFY_PROMO_CODE = `${ECW_CAMPAIGN_FORM} VERIFY_PROMO_CODE`;
