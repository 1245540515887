import React from 'react';
import { makeSelectAdvertiser } from 'containers/App/selectors';
import { useSelector, useDispatch } from 'react-redux';
import DashboardHeader from './DashboardHeader';
import styles from './DashboardLayout.module.scss';
import DashboardSideBar from './DashboardSideBar';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Link, useLocation } from 'react-router-dom';
import { resendVerificationLink } from 'containers/App/actions';
import { NOT_FOUND } from '../../../routes';

function DashboardLayout({ hasNavigationMenu, children }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  const excludeDashboardForRoutes = [NOT_FOUND];

  const advertiser = useSelector(makeSelectAdvertiser());

  const handleResendLink = (e) => {
    e.preventDefault();
    dispatch(resendVerificationLink());
  };

  const shouldRenderNavbarSidebar = () =>
    !excludeDashboardForRoutes.includes(pathname);

  return (
    <div className={styles.container}>
      {!hasNavigationMenu ? (
        <> {children} </>
      ) : (
        <>
          {shouldRenderNavbarSidebar() && <DashboardHeader />}
          {!advertiser?.verifiedAt && (
            <p className={styles.verificationMessage}>
              {formatMessage(messages.verificationMessage)}{' '}
              <Link to="#" onClick={handleResendLink}>
                {formatMessage(messages.resendLink)}
              </Link>
            </p>
          )}
          {shouldRenderNavbarSidebar() && <DashboardSideBar />}
        </>
      )}
    </div>
  );
}

export default DashboardLayout;
