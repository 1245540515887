import {
  FETCH_OBJECTIVES_ATTEMPT,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVES_ERROR,
} from './constants';

export const initialState = {
  fetchObjectives: {
    attempt: false,
    success: false,
    error: null,
  },
  objectivesByCategories: [],
};

const objectiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OBJECTIVES_ATTEMPT:
      return {
        ...state,
        fetchObjectives: { attempt: true, success: false, error: null },
      };
    case FETCH_OBJECTIVES_SUCCESS:
      return {
        ...state,
        fetchObjectives: { attempt: false, success: true, error: null },
        objectivesByCategories: action.payload,
      };
    case FETCH_OBJECTIVES_ERROR:
      return {
        ...state,
        fetchObjectives: {
          attempt: false,
          success: false,
          error: action.payload,
        },
        objectivesByCategories: [],
      };
    default:
      return state;
  }
};

export default objectiveReducer;
