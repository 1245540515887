import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { ENDPOINTS } from '../../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { fetchFreeSlotsDataSuccess } from './actions';
import { FREE_SLOTS_REQUEST } from './constants';

export function* fetchFreeSlotsRequest() {
  try {
    const freeSlotsData = yield call(request, {
      url: ENDPOINTS.FREE_SLOTS,
      method: 'get',
    });
    yield put(fetchFreeSlotsDataSuccess(freeSlotsData));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export default function* freeSlotsSaga() {
  yield takeLatest(FREE_SLOTS_REQUEST, fetchFreeSlotsRequest);
}
