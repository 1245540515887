import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from '../FormFieldStyles.module.scss';
import Error from '../Error';
import Tooltip from 'components/Tooltip';

const SelectField = ({
  name,
  label,
  placeholder,
  required,
  containerStyle = '',
  inputFieldLarge,
  tooltipText,
  options,
}) => {
  const { formatMessage } = useIntl();

  const inputFieldClasses = classNames(styles.field, {
    [containerStyle]: containerStyle,
  });

  return (
    <div className={inputFieldClasses}>
      <label className={styles.label} htmlFor={name}>
        {label}
        {required && <span className={styles.requiredSign}>*</span>}
        {tooltipText && <Tooltip label={tooltipText} />}
      </label>
      <Field name={name} required={required}>
        {({ field }) => (
          <select className={styles.selectInput} {...field}>
            <option
              className={styles.placeholder}
              disabled
              defaultValue
              value=""
            >
              {placeholder}
            </option>
            {options.map((item, index) => (
              <option className={styles.option} value={item.id} key={index}>
                {typeof item.label === 'string'
                  ? item.label
                  : formatMessage(item.label)}
              </option>
            ))}
          </select>
        )}
      </Field>
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default SelectField;

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
};
