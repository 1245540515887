import {
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPES,
  ENDPOINTS,
  FILE_TYPE,
  GOOGLE_MAPS,
} from '../../constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import {
  advertiserCampaignGalleryError,
  advertiserCampaignGallerySuccess,
  advertiserGalleryError,
  advertiserGallerySuccess,
  createCampaignError,
  deleteGalleryEntriesError,
  deleteGalleryEntriesSuccess,
  fetchDuplicateCampaignSuccess,
  postToAdvertiserGalleryError,
  postToAdvertiserGallerySuccess,
} from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import {
  ADVERTISER_CAMPAIGN_GALLERY_REQUEST,
  DELETE_GALLERY_ENTRIES_REQUEST,
  DRAFT_CAMPAIGN_REQUEST,
  ECW_ADVERTISER_GALLERY_REQUEST,
  PUBLISH_CAMPAIGN_REQUEST,
  DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE,
  PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE,
  FETCH_DUPLICATE_CAMPAIGN_REQUEST,
  POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  VERIFY_PROMO_CODE,
} from './constants';
import config from 'config';
import { format } from 'util';
import { getLocationData } from 'utils/googleLocations';
import { push } from 'connected-react-router';
import { SINGLE_CAMPAIGN } from 'routes';
import { setIsEditMode } from 'containers/SingleCampaignPage/actions';
import { removeTemporaryData } from 'containers/CreateCampaignPage/actions';
import { handleBadRequestAndUnauthorised } from 'utils/handleBadRequestAndUnauthorised';

function* postToAdvertiserGallery({
  payload: { title, meta, tags, type, advertiserId, file },
  meta: { setFieldValue, onClose },
}) {
  try {
    let data = null;

    const formData = new FormData();

    formData.append('title', title);
    formData.append('type', type);
    formData.append('meta', meta);
    formData.append('tags', tags);
    formData.append('advertiserId', advertiserId);

    if (type === FILE_TYPE.VIDEO) {
      formData.append(type, file);
      data = yield call(request, {
        url: ENDPOINTS.UPLOAD_VIDEO,
        method: 'post',
        data: formData,
      });

      yield call(
        setFieldValue,
        'companyInformation.video',
        data.galleryMedia.path
      );
      yield call(
        setFieldValue,
        'companyInformation.videoUrl',
        data.galleryMedia.url
      );
      yield call(
        setFieldValue,
        'companyInformation.isExternalVideo',
        data.galleryMedia.isExternalPath
      );
    } else {
      formData.append(type, file);

      data = yield call(request, {
        url: ENDPOINTS.UPLOAD_PHOTO,
        method: 'post',
        data: formData,
      });

      yield call(
        setFieldValue,
        'companyInformation.logo',
        data.galleryMedia.path
      );

      yield call(
        setFieldValue,
        'companyInformation.logoUrl',
        data.galleryMedia.url
      );
      yield call(
        setFieldValue,
        'companyInformation.thumbnail',
        data.galleryMedia.thumbnail
      );

      yield call(
        setFieldValue,
        'companyInformation.isExternalLogo',
        data.galleryMedia.isExternalPath
      );
    }

    yield put(
      enqueueSnackbar({
        message: 'Media uploaded',
        variant: toasterVariants.success,
      })
    );

    yield call(onClose);

    yield put(postToAdvertiserGallerySuccess(data));
  } catch (err) {
    yield put(postToAdvertiserGalleryError());
  }
}

function* postToAdvertiserGalleryExternal({
  payload: { title, meta, tags, type, advertiserId, path },
  meta: { setFieldValue, onClose },
}) {
  try {
    let data = {
      title,
      meta,
      tags,
      type,
      advertiserId: advertiserId + '',
      path,
    };

    data = yield call(request, {
      url: ENDPOINTS.UPLOAD_EXTERNAL_MEDIA,
      method: 'post',
      data: data,
    });

    if (type === FILE_TYPE.VIDEO) {
      yield call(
        setFieldValue,
        'companyInformation.video',
        data.galleryMedia.path
      );
      yield call(
        setFieldValue,
        'companyInformation.videoUrl',
        data.galleryMedia.url
      );
      yield call(
        setFieldValue,
        'companyInformation.isExternalVideo',
        data.galleryMedia.isExternalPath
      );
    } else {
      yield call(
        setFieldValue,
        'companyInformation.logo',
        data.galleryMedia.path
      );

      yield call(
        setFieldValue,
        'companyInformation.logoUrl',
        data.galleryMedia.url
      );
      yield call(
        setFieldValue,
        'companyInformation.thumbnail',
        data.galleryMedia.thumbnail
      );

      yield call(
        setFieldValue,
        'companyInformation.isExternalLogo',
        data.galleryMedia.isExternalPath
      );
    }

    yield call(onClose);

    yield put(
      enqueueSnackbar({
        message: 'Media uploaded',
        variant: toasterVariants.success,
      })
    );

    yield put(postToAdvertiserGallerySuccess(data));
  } catch (err) {
    yield put(postToAdvertiserGalleryError());
  }
}

export function* getAdvertiserGallery({ advertiserId }) {
  try {
    const advertiserGallery = yield call(request, {
      url: ENDPOINTS.ADVERTISER_GALLERY.replace('{advertiserId}', advertiserId),
      method: 'get',
    });
    yield put(advertiserGallerySuccess(advertiserGallery));
  } catch (err) {
    yield put(advertiserGalleryError());
  }
}

export function* getAdvertiserCampaignGallery({ advertiserId }) {
  try {
    const advertiserCampaignGallery = yield call(request, {
      url: ENDPOINTS.ADVERTISER_CAMPAIGN_GALLERY.replace(
        '{advertiserId}',
        advertiserId
      ),
      method: 'get',
    });
    yield put(advertiserCampaignGallerySuccess(advertiserCampaignGallery));
  } catch (err) {
    console.log('err', err);
    yield put(advertiserCampaignGalleryError());
  }
}

export function* deleteGalleryEntries({ galleryEntryIds, setSelectedMedias }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELETE_GALLERY_ENTRY,
      data: galleryEntryIds,
      method: 'delete',
    });

    yield put(deleteGalleryEntriesSuccess(galleryEntryIds));
    yield call(setSelectedMedias, []);
  } catch (err) {
    yield put(deleteGalleryEntriesError());
  }
}

export function* draftCampaign({ campaignData }) {
  try {
    yield put({ type: DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
    if (campaignData.type !== CAMPAIGN_TYPES.DIGITAL) {
      const centerPointLocationData = yield call(request, {
        url: format(
          GOOGLE_MAPS.GEOCODE_API_URL,
          campaignData.location.centerPoint.latitude,
          campaignData.location.centerPoint.longitude,
          config.googleMaps.apiKey
        ),
        method: 'get',
        withoutToken: true,
        allowHeaders: false,
      });
      const data = getLocationData(centerPointLocationData);
      campaignData.location.city = data.city;
      campaignData.location.address = data.address;
    } else {
      delete campaignData.location;
    }
    const createdCampaign = yield call(request, {
      url: ENDPOINTS.DRAFT_CAMPAIGN,
      method: 'post',
      data: campaignData,
    });
    yield put(
      enqueueSnackbar({
        message: 'Campaign drafted',
        variant: toasterVariants.success,
      })
    );
    yield put(removeTemporaryData());
    yield put(setIsEditMode(false));
    yield put(push(SINGLE_CAMPAIGN.replace(':id', createdCampaign.id)));
    yield put({ type: DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
  } catch (err) {
    yield put(createCampaignError());
    yield put(
      enqueueSnackbar({
        message: err.data.message,
      })
    );
    yield put({ type: DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
  }
}

export function* publishCampaign({ campaignData }) {
  try {
    yield put({ type: PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
    if (campaignData.type !== CAMPAIGN_TYPES.DIGITAL) {
      const centerPointLocationData = yield call(request, {
        url: format(
          GOOGLE_MAPS.GEOCODE_API_URL,
          campaignData.location.centerPoint.latitude,
          campaignData.location.centerPoint.longitude,
          config.googleMaps.apiKey
        ),
        method: 'get',
        withoutToken: true,
        allowHeaders: false,
      });
      const data = getLocationData(centerPointLocationData);
      campaignData.location.city = data.city;
      campaignData.location.address = data.address;
    } else {
      delete campaignData.location;
    }
    const createdCampaign = yield call(request, {
      url: ENDPOINTS.PUBLISH_CAMPAIGN,
      method: 'post',
      data: campaignData,
    });

    if (createdCampaign.status !== CAMPAIGN_STATUS.DRAFT) {
      yield put(
        enqueueSnackbar({
          message: 'Campaign published',
          variant: toasterVariants.success,
        })
      );
    } else {
      // publish -> draft only if payment not successful
      yield put(
        enqueueSnackbar({
          message: 'We had issues proccessing your payment',
          variant: toasterVariants.error,
        })
      );
    }

    yield put(removeTemporaryData());
    yield put(setIsEditMode(false));
    yield put(push(SINGLE_CAMPAIGN.replace(':id', createdCampaign.id)));
    yield put({ type: PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
  } catch (err) {
    yield put(createCampaignError());
    yield put(
      enqueueSnackbar({
        message: err.data.message,
      })
    );
    yield put({ type: PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE });
  }
}

export function* fetchDuplicateCampaignRequest({ id }) {
  try {
    const singleCampaign = yield call(request, {
      url: ENDPOINTS.SINGLE_ECW_CAMPAIGN.replace('{id}', id),
      method: 'get',
    });
    yield put(fetchDuplicateCampaignSuccess(singleCampaign));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* verifyPromoCode({ promoCode, meta: { onSuccess } }) {
  try {
    const coupon = yield call(request, {
      url: ENDPOINTS.VERIFY_PROMO_CODE.replace('{promoCode}', promoCode),
      method: 'get',
    });
    onSuccess(coupon);
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export default function* ecwCreateCampaignSaga() {
  yield takeLatest(DELETE_GALLERY_ENTRIES_REQUEST, deleteGalleryEntries);
  yield takeLatest(ECW_ADVERTISER_GALLERY_REQUEST, getAdvertiserGallery);
  yield takeLatest(
    ADVERTISER_CAMPAIGN_GALLERY_REQUEST,
    getAdvertiserCampaignGallery
  );
  yield takeLatest(DRAFT_CAMPAIGN_REQUEST, draftCampaign);
  yield takeLatest(PUBLISH_CAMPAIGN_REQUEST, publishCampaign);
  yield takeLatest(
    FETCH_DUPLICATE_CAMPAIGN_REQUEST,
    fetchDuplicateCampaignRequest
  );
  yield takeLatest(
    POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
    postToAdvertiserGallery
  );
  yield takeLatest(
    POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
    postToAdvertiserGalleryExternal
  );
  yield takeLatest(VERIFY_PROMO_CODE, verifyPromoCode);
}
