import React, { useState, useEffect } from 'react';
import config from 'config';
import AuthLayout from 'components/Layout/Pages/AuthPage/AuthLayout';
import ContentBox from 'components/ContentBox';
import PasswordProtectionForm from './PasswordProtectionForm';
import styles from './PasswordProtection.module.scss';

const PASSWORD_PROTECTION_PASSED_SESSION_KEY = 'password_protection_passed';

export default function PasswordProtection({ children }) {
  const protectionEnabled = config.passwordProtection.isEnabled;
  const phrase = config.passwordProtection.phrase;

  const [passwordProtectionPassed, setPasswordProtectionPassed] = useState(
    JSON.parse(sessionStorage.getItem(PASSWORD_PROTECTION_PASSED_SESSION_KEY))
  );

  useEffect(() => {
    if (passwordProtectionPassed) {
      sessionStorage.setItem(
        PASSWORD_PROTECTION_PASSED_SESSION_KEY,
        JSON.stringify(passwordProtectionPassed)
      );
    }
  }, [passwordProtectionPassed]);

  const handleSubmit = ({ password }) => {
    setPasswordProtectionPassed(phrase === password);
  };

  if (protectionEnabled && !passwordProtectionPassed) {
    return (
      <main className={styles.container}>
        <AuthLayout>
          <ContentBox className={styles.contentBox}>
            <PasswordProtectionForm onSubmit={handleSubmit} />
          </ContentBox>
        </AuthLayout>
      </main>
    );
  }

  return children;
}
