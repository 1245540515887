import {
  FETCH_OBJECTIVES_ATTEMPT,
  FETCH_OBJECTIVES_SUCCESS,
  FETCH_OBJECTIVES_ERROR,
  FETCH_OBJECTIVES_REQUEST,
} from './constants';

export function fetchObjectivesRequest() {
  return {
    type: FETCH_OBJECTIVES_REQUEST,
  };
}

export function fetchObjectivesAttempt() {
  return {
    type: FETCH_OBJECTIVES_ATTEMPT,
  };
}

export function fetchObjectivesSuccess(data) {
  return {
    type: FETCH_OBJECTIVES_SUCCESS,
    payload: data,
  };
}

export function fetchObjectivesError(error) {
  return {
    type: FETCH_OBJECTIVES_ERROR,
    payload: error,
  };
}
