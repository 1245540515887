import { WS_CAMPAIGN_STATUS_CHANGED } from 'containers/SingleCampaignPage/constants';
import produce from 'immer';
import {
  FETCH_ACTIVE_CAMPAIGNS_REQUEST,
  FETCH_ACTIVE_CAMPAIGNS_SUCCESS,
  FETCH_UPCOMING_CAMPAIGNS_REQUEST,
  FETCH_UPCOMING_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  FETCH_CAMPAIGNS_LIST_SUCCESS,
  GET_STATISTICS_ATTEMPT,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR,
  GET_STATISTICS_RESET,
  GET_CAMPAIGNS_FOR_TIME_INTERVAL_ATTEMPT,
  GET_CAMPAIGNS_FOR_TIME_INTERVAL_SUCCESS,
  GET_CAMPAIGNS_FOR_TIME_INTERVAL_ERROR,
  GET_CAMPAIGNS_FOR_TIME_INTERVAL_RESET,
  CHANGE_CAMPAIGN_STATUS,
} from './constants';

export const initialState = {
  areActiveCampaignsLoading: false,
  activeCampaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
  },
  areUpcomingCampaignsLoading: false,
  upcomingCampaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
  },
  statistics: {},
  getStatisticsStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  campaignsForTimeInterval: [],
  getCampaignsForTimeIntervalStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  campaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
};

/* eslint-disable default-case */
const dashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_ACTIVE_CAMPAIGNS_REQUEST:
        draft.areActiveCampaignsLoading = true;
        break;
      case FETCH_ACTIVE_CAMPAIGNS_SUCCESS:
        draft.areActiveCampaignsLoading = false;
        draft.activeCampaigns = {
          ...action.activeCampaigns,
          data:
            action.activeCampaigns.page === 1
              ? action.activeCampaigns.data
              : [...draft.activeCampaigns.data, ...action.activeCampaigns.data],
        };
        break;
      case FETCH_UPCOMING_CAMPAIGNS_REQUEST:
        draft.areUpcomingCampaignsLoading = true;
        break;
      case FETCH_UPCOMING_CAMPAIGNS_SUCCESS:
        draft.areUpcomingCampaignsLoading = false;
        draft.upcomingCampaigns = {
          ...action.upcomingCampaigns,
          data:
            action.upcomingCampaigns.page === 1
              ? action.upcomingCampaigns.data
              : [
                  ...draft.upcomingCampaigns.data,
                  ...action.upcomingCampaigns.data,
                ],
        };
        break;
      case FETCH_CAMPAIGNS_LIST_REQUEST:
        draft.areCampaignsLoading = true;
        break;
      case FETCH_CAMPAIGNS_LIST_SUCCESS:
        draft.areCampaignsLoading = false;
        draft.campaigns.perPage = action.campaigns.perPage;
        draft.campaigns.page = action.campaigns.page;
        draft.campaigns.total = action.campaigns.total;
        draft.campaigns.search = action.search;
        draft.campaigns.sortBy = action.sortBy;
        draft.campaigns.order = action.order;
        draft.campaigns.data =
          action.campaigns.page === 1
            ? action.campaigns.data
            : [...draft.campaigns.data, ...action.campaigns.data];
        break;
      case CHANGE_CAMPAIGN_STATUS:
        const campaignId = action.campaignId;
        const status = action.status;
        const newCampaigns = draft.campaigns.data.map((campaign) => {
          if (campaign.id === campaignId) {
            return {
              ...campaign,
              status: status,
            };
          } else {
            return campaign;
          }
        });
        draft.campaigns.data = newCampaigns;
        break;
      case GET_STATISTICS_ATTEMPT:
        draft.getStatisticsStatus = {
          attempt: true,
          success: false,
          error: null,
        };
        break;
      case GET_STATISTICS_SUCCESS:
        draft.statistics = action.statistics;
        draft.getStatisticsStatus = {
          attempt: false,
          success: true,
          error: null,
        };
        break;
      case GET_STATISTICS_ERROR:
        draft.getStatisticsStatus = {
          attempt: false,
          success: false,
          error: action.error,
        };
        break;
      case GET_STATISTICS_RESET:
        draft.statistics = {};
        draft.getStatisticsStatus = {
          attempt: false,
          success: false,
          error: null,
        };
        break;
      case GET_CAMPAIGNS_FOR_TIME_INTERVAL_ATTEMPT:
        draft.getCampaignsForTimeIntervalStatus = {
          attempt: true,
          success: false,
          error: null,
        };
        break;
      case GET_CAMPAIGNS_FOR_TIME_INTERVAL_SUCCESS:
        draft.campaignsForTimeInterval = action.campaigns;
        draft.getCampaignsForTimeIntervalStatus = {
          attempt: false,
          success: true,
          error: null,
        };
        break;
      case WS_CAMPAIGN_STATUS_CHANGED:
        const campaigns = draft.campaigns.data.map((campaign) =>
          action.campaignId === campaign.id
            ? { ...campaign, status: action.status }
            : campaign
        );
        draft.campaigns.data = campaigns;
        break;
      case GET_CAMPAIGNS_FOR_TIME_INTERVAL_ERROR:
        draft.getCampaignsForTimeIntervalStatus = {
          attempt: false,
          success: false,
          error: action.error,
        };
        break;
      case GET_CAMPAIGNS_FOR_TIME_INTERVAL_RESET:
        draft.campaignsForTimeInterval = [];
        draft.getCampaignsForTimeIntervalStatus = {
          attempt: false,
          success: false,
          error: null,
        };
        break;
    }
  });

export default dashboardReducer;
