import {
  GUEST_CREATE_CAMPAIGN_REQUEST,
  REMOVE_TEMPORARY_CAMPAIGN_FORM_DATA,
} from './constants';

export function guestCreateCampaign(campaignData) {
  return {
    type: GUEST_CREATE_CAMPAIGN_REQUEST,
    campaignData,
  };
}

export function removeTemporaryData() {
  return {
    type: REMOVE_TEMPORARY_CAMPAIGN_FORM_DATA,
  };
}
