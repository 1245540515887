const EyCrowd = '- EyCrowd';

export const ABOUT_US = `About Us ${EyCrowd}`;
export const ADVERTISER_SETTINGS = `Advertiser Settings ${EyCrowd}`;
export const ADVERTISERS = `Advertisers ${EyCrowd}`;
export const TIMELINE = `Timeline ${EyCrowd}`;
export const STATISTICS = `Statistics ${EyCrowd}`;
export const CAMPAIGNS = `Campaigns ${EyCrowd}`;
export const CONTACT = `Contact Us ${EyCrowd}`;
export const CAMPAIGN_OBJECTIVES = `Campaign Objectives ${EyCrowd}`;
export const CREATE_CAMPAIGN = `Create Campaign ${EyCrowd}`;
export const CREATE_CAMPAIGN_DASHBOARD = `Create Campaign Dashboard ${EyCrowd}`;
export const DASHBOARD = `Dashboard ${EyCrowd}`;
export const ERROR = `Error ${EyCrowd}`;
export const FORGOT_PASSWORD = `Forgot Password ${EyCrowd}`;
export const WE_ARE_HIRING = `We're Hiring ${EyCrowd}`;
export const LOGIN = `Login ${EyCrowd}`;
export const PAGE_NOT_FOUND = `Page Not Found ${EyCrowd}`;
export const PRIVACY_POLICY = `Privacy Policy ${EyCrowd}`;
export const REGISTER = `Register ${EyCrowd}`;
export const REPORTS = `Reports ${EyCrowd}`;
export const RESET_PASSWORD = `Reset Password ${EyCrowd}`;
export const CAMPAIGN = `Campaign ${EyCrowd}`;
export const TERMS_AND_CONDITIONS = `Terms And Conditions ${EyCrowd}`;
export const WELCOME_PAGE = `Welcome Page ${EyCrowd}`;
export const NEED_HELP = `FAQ ${EyCrowd}`;
