export const CAMPAIGNS_LIST = '[CampaignsList]';

export const FETCH_ACTIVE_CAMPAIGNS_REQUEST = `${CAMPAIGNS_LIST} FETCH_ACTIVE_CAMPAIGNS_REQUEST`;
export const FETCH_ACTIVE_CAMPAIGNS_SUCCESS = `${CAMPAIGNS_LIST} FETCH_ACTIVE_CAMPAIGNS_SUCCESS`;
export const FETCH_UPCOMING_CAMPAIGNS_REQUEST = `${CAMPAIGNS_LIST} FETCH_UPCOMING_CAMPAIGNS_REQUEST`;
export const FETCH_UPCOMING_CAMPAIGNS_SUCCESS = `${CAMPAIGNS_LIST} FETCH_UPCOMING_CAMPAIGNS_SUCCESS`;

export const FETCH_CAMPAIGNS_LIST_REQUEST = `${CAMPAIGNS_LIST} FETCH_CAMPAIGNS_LIST_REQUEST`;
export const FETCH_CAMPAIGNS_LIST_SUCCESS = `${CAMPAIGNS_LIST} FETCH_CAMPAIGNS_LIST_SUCCESS`;

export const GET_STATISTICS_ATTEMPT = `${CAMPAIGNS_LIST} GET_STATISTICS_ATTEMPT`;
export const GET_STATISTICS_SUCCESS = `${CAMPAIGNS_LIST} GET_STATISTICS_SUCCESS`;
export const GET_STATISTICS_ERROR = `${CAMPAIGNS_LIST} GET_STATISTICS_ERROR`;
export const GET_STATISTICS_RESET = `${CAMPAIGNS_LIST} GET_STATISTICS_RESET`;

export const GET_CAMPAIGNS_FOR_TIME_INTERVAL_ATTEMPT = `${CAMPAIGNS_LIST} GET_CAMPAIGNS_FOR_TIME_INTERVAL_ATTEMPT`;
export const GET_CAMPAIGNS_FOR_TIME_INTERVAL_SUCCESS = `${CAMPAIGNS_LIST} GET_CAMPAIGNS_FOR_TIME_INTERVAL_SUCCESS`;
export const GET_CAMPAIGNS_FOR_TIME_INTERVAL_ERROR = `${CAMPAIGNS_LIST} GET_CAMPAIGNS_FOR_TIME_INTERVAL_ERROR`;
export const GET_CAMPAIGNS_FOR_TIME_INTERVAL_RESET = `${CAMPAIGNS_LIST} GET_CAMPAIGNS_FOR_TIME_INTERVAL_RESET`;

export const CHANGE_CAMPAIGN_STATUS = `${CAMPAIGNS_LIST} CHANGE_CAMPAIGN_STATUS`;
