import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeSelectIsAuthenticated } from 'containers/App/selectors';
import { LOGIN } from 'routes';

export function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(makeSelectIsAuthenticated());

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={LOGIN} />
      }
    />
  );
}

export default PrivateRoute;
