import React from 'react';
import { Form, Formik } from 'formik';
import { PasswordField } from 'components/Forms';
import Button from 'components/Buttons/Button';
import styles from './PasswordProtection.module.scss';

export default function PasswordProtectionForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <PasswordField
            name="password"
            placeholder="Password"
            required
            labelHidden
          />
          <Button
            alt
            type="submit"
            title="Continue"
            isLarge
            className={styles.button}
          />
        </Form>
      )}
    </Formik>
  );
}
