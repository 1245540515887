const { FREE_SLOTS_REQUEST, FREE_SLOTS_SUCCESS } = require('./constants');

export const fetchFreeSlotsDataRequest = () => {
  return {
    type: FREE_SLOTS_REQUEST,
  };
};

export const fetchFreeSlotsDataSuccess = (freeSlots) => {
  return {
    type: FREE_SLOTS_SUCCESS,
    data: freeSlots,
  };
};
