import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import config from 'config';
import history from 'utils/history';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import configureStore from 'configureStore';
import * as serviceWorker from 'serviceWorker';
import { translationMessages } from './i18n';
import '../src/styles/main.scss';
import PasswordProtection from 'components/PasswordProtection';
import { ErrorBoundaryView } from './containers/ErrorBoundary/ErrorBoundryView.presentation';
import { FacebookProvider } from 'react-facebook';
import TagManager from 'react-gtm-module';

let ErrorBoundary = ({ children }) => children;

if (
  process.env.REACT_APP_BUGSNAG_API_KEY &&
  process.env.REACT_APP_BUGSNAG_ENABLED
) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    // enabledReleaseStages: ['production'],
    // releaseStage: process.env.NODE_ENV,
  });

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

const initialState = {};
export const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('root');

if (config.sentry.key && config.sentry.project) {
  Sentry.init({
    dsn: `https://${config.sentry.key}@sentry.io/${config.sentry.project}`,
  });
}
if (config.googleTagManager.id && config.googleTagManager.enabled === 'true') {
  TagManager.initialize({
    gtmId: config.googleTagManager.id,
  });
}

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
            <PasswordProtection>
              <FacebookProvider appId={config.social.facebookAppId}>
                <App />
              </FacebookProvider>
            </PasswordProtection>
          </ErrorBoundary>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import(/* webpackChunkName: "import-intl-chunk" */ 'intl'));
  })
    .then(() =>
      Promise.all([
        import(
          /* webpackChunkName: "intl-locale-data-jsonp-en-chunk" */ 'intl/locale-data/jsonp/en.js'
        ),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
