export const ENDPOINTS = {
  AUTH_ME: '/auth/me',
  AUTH_ADVERTISER_LOGIN: '/auth/business-representative/login',
  AUTH_ADVERTISER_REGISTER: '/auth/business-representative/register',
  AUTH_REFRESH_TOKEN: '/auth/refresh-token',
  AUTH_LOGOUT: '/auth/logout',
  PASSWORD_CHANGE: '/auth/business-representative/reset-password',
  ADVERTISER_PROFILE: '/business-representative/company',
  ADVERTISER_COMPANY_LOGO: '/business-representative/company/logo',
  CAMPAIGN_LOGO: '/business-representative/campaigns/logo',
  CAMPAIGN_VIDEO: '/business-representative/campaigns/video',
  PAYMENT_METHODS: '/business-representative/company/payment-methods',
  PAYMENT_METHOD: '/business-representative/company/payment-methods/%s',
  DEFAULT_PAYMENT_METHOD:
    '/business-representative/company/payment-methods/%s/default',
  COMMON_DATA: '/common-data',
  CAMPAIGN_COMMON_DATA: '/campaign-common-data',
  CAMPAIGN_COMMON_DATA_NOT_AUTHENTICATED:
    '/campaign-common-data-not-authenticated',
  EYVOCATE_DEMOGRAPHICS_LOCATIONS: '/google/places/%s',
  CAMPAIGNS: '/business-representative/campaigns',
  CAMPAIGNS_LIST:
    '/business-representative/campaigns?page=%s&perPage=%s&search=%s&status=%s&sortBy=%s&order=%s',
  STATISTICS: '/business-representative/campaigns/statistics',
  CAMPAIGNS_FOR_TIME_INTERVAL:
    '/business-representative/campaigns/interval?startDate=%s&endDate=%s',
  SINGLE_CAMPAIGN: '/business-representative/campaigns/{id}',
  SINGLE_ECW_CAMPAIGN: '/business-representative/campaigns/ecw/{id}',
  SINGLE_CAMPAIGN_GALLERY:
    '/business-representative/campaigns/{id}/media?perPage=%s&isSupervisor=%s&page=%s',
  ECW_SINGLE_CAMPAIGN_GALLERY:
    '/gallery/business-representative/campaign/{id}?perPage=%s&page=%s',
  FORGOT_PASSWORD: '/auth/business-representative/forgot-password',
  RESET_PASSWORD: '/auth/business-representative/reset-forgot-password',
  ACCOUNT_VERIFICATION: '/auth/business-representative/verify-account',
  CUSTOM_NOTIFICATION:
    '/business-representative/campaigns/{campaignId}/custom-notifications',
  CUSTOM_NOTIFICATIONS:
    '/business-representative/campaigns/{campaignId}/custom-notifications?page=%s&perPage=%s',
  INVITE: '/business-representative/campaigns/{campaignId}/invitation-link',
  CONTACT: '/contact-form',
  RESEND_VERIFICATION_LINK: '/business-representative/resend-verification-link',
  JOBS: '/jobs',
  TIMEZONES: '/timezones',
  OBJECTIVES_BY_CATEGORIES: '/objectives',
  UPLOAD_PHOTO: '/gallery/photo',
  UPLOAD_VIDEO: '/gallery/video',
  UPLOAD_EXTERNAL_MEDIA: '/gallery/external/media',
  ADVERTISER_GALLERY: '/gallery/business-representative/{advertiserId}',
  ADVERTISER_CAMPAIGN_GALLERY:
    '/gallery/business-representative/{advertiserId}/campaigns',
  DELETE_GALLERY_ENTRY: '/gallery/media',
  DRAFT_CAMPAIGN: '/business-representative/campaigns/draft',
  PUBLISH_CAMPAIGN: '/business-representative/campaigns/publish',
  UPDATE_PARTIAL_COMPANY_INFO: '/business-representative/company/info',
  FREE_SLOTS: '/business-representative/campaigns/free-slots-data',
  ADD_SOCIAL_ACCOUNT: '/business-representative/social-account',
  VERIFY_SOCIAL_ACCOUNT: '/social-platforms/verify-business-representative',
  CAMPAIGN_TEMPLATES: 'campaign-templates?page=%s&perPage=%s&search=%s',
  SUBMIT_FEEDBACK: '/feedback',
  VERIFY_PROMO_CODE: '/coupons/verify/{promoCode}',
  CAMPAIGN_CONTENT: '/business-representative/campaigns/{campaignId}/content',
  PUBLISH_SIMPLE_CAMPAIGN: '/business-representative/campaigns/publish/simple',
  PUBLISH_PUBLISHABLE_CAMPAIGN:
    '/business-representative/campaigns/publish/{campaignId}',
};
