import { createSelector } from 'reselect';
import { NONE_DISABILITY } from '../../constants';
import { initialState } from './reducer';

const selectApp = (state) => state.app || initialState;

const makeSelectAdvertiser = () =>
  createSelector(selectApp, (substate) => substate.advertiser);

const makeSelectIsAuthenticated = () =>
  createSelector(selectApp, (substate) => !!substate.token);

const makeSelectIsAdvertiserVerified = () =>
  createSelector(selectApp, (substate) => !!substate.advertiser?.verifiedAt);

const makeSelectIsImpersonated = () =>
  createSelector(
    selectApp,
    (substate) => !!substate.advertiser?.isImpersonated
  );

const makeSelectAdvertiserSocialAccounts = () =>
  createSelector(selectApp, (substate) => substate.advertiser?.socialAccounts);

const makeSelectError = () =>
  createSelector(selectApp, (substate) => substate.error);

const makeSelectToken = () =>
  createSelector(selectApp, (substate) => substate.token);

const makeSelectInterests = () =>
  createSelector(selectApp, (substate) => substate.interests);

const makeSelectAgeRanges = () =>
  createSelector(selectApp, (substate) => substate.ageRanges);

const makeSelectDisabilities = () =>
  createSelector(selectApp, (substate) => substate.disabilities);

const makeSelectTimezones = () =>
  createSelector(selectApp, (substate) => substate.timezones);

const makeSelectTaskTemplates = () =>
  createSelector(selectApp, (substate) => substate.taskTemplates);

const makeSelectEyVocateLocations = () =>
  createSelector(selectApp, (substate) => substate.eyVocateLocations);

const makeSelectCampaignTemplates = () =>
  createSelector(selectApp, (substate) => substate.campaignTemplates);

const makeSelectEyVocateLocationsError = () =>
  createSelector(
    selectApp,
    (substate) => substate.fetchEyVocateLocations.error
  );

const makeSelectBusinessRulesVariables = () =>
  createSelector(selectApp, (substate) => substate.businessRulesVariables);

const makeSelectDeafultSupervisorTasks = () =>
  createSelector(selectApp, (substate) => substate.defaultSupervisorTasks);

const makeSelectNoneDisability = () =>
  createSelector(selectApp, (substate) =>
    substate.disabilities.find(
      (disability) => disability.slug === NONE_DISABILITY
    )
  );

const makeSelectSocialPlatforms = () =>
  createSelector(selectApp, (substate) => substate.socialPlatforms);

const makeSelectAddSocialAccount = () =>
  createSelector(selectApp, (substate) => substate.addSocialAccount);

const makeSelectIsPending = () =>
  createSelector(selectApp, (substate) => substate.isPending);

const makeSelectPaymentMethods = () =>
  createSelector(selectApp, (substate) => substate.paymentMethods);

const makeSelectVerificationToken = () =>
  createSelector(selectApp, (substate) => substate.verificationToken);

const makeSelectMobile = () =>
  createSelector(selectApp, (substate) => substate.isMobile);

const makeSelectDisabledSubmitPublish = () =>
  createSelector(selectApp, (substate) => substate.disabledSubmitPublish);

const makeSelectUpdatePartialCompanyInfoSuccess = () =>
  createSelector(
    selectApp,
    (substate) => substate.updatePartialCompanyInfoSuccess
  );

const makeSelectVerifySocialAccountSuccess = () =>
  createSelector(selectApp, (substate) => substate.verifySocialAccountSuccess);

const makeSelectDeleteSocialAccount = () =>
  createSelector(selectApp, (substate) => substate.deleteSocialAccountSuccess);

export {
  makeSelectAdvertiser,
  makeSelectError,
  makeSelectIsAuthenticated,
  makeSelectIsImpersonated,
  makeSelectToken,
  makeSelectInterests,
  makeSelectDisabilities,
  makeSelectAgeRanges,
  makeSelectTimezones,
  makeSelectTaskTemplates,
  makeSelectEyVocateLocations,
  makeSelectEyVocateLocationsError,
  makeSelectBusinessRulesVariables,
  makeSelectSocialPlatforms,
  makeSelectIsPending,
  makeSelectPaymentMethods,
  makeSelectDeafultSupervisorTasks,
  makeSelectNoneDisability,
  makeSelectVerificationToken,
  makeSelectIsAdvertiserVerified,
  makeSelectMobile,
  makeSelectDisabledSubmitPublish,
  makeSelectUpdatePartialCompanyInfoSuccess,
  makeSelectAddSocialAccount,
  makeSelectVerifySocialAccountSuccess,
  makeSelectDeleteSocialAccount,
  makeSelectCampaignTemplates,
  makeSelectAdvertiserSocialAccounts,
};
