const getFormattedAddress = (centerPoint) => {
  return centerPoint.results[0].formatted_address;
};

const getAddress = (formattedAddress) => {
  return formattedAddress.substr(0, formattedAddress.indexOf(','));
};

const getCity = (formattedAddress) => {
  return formattedAddress.substr(
    formattedAddress.indexOf(',') + 2,
    formattedAddress.length
  );
};

export const formatLocationData = (campaignData, locationData) => {
  const formattedAddress = getFormattedAddress(locationData);

  campaignData.location.address = getAddress(formattedAddress);
  campaignData.location.city = getCity(formattedAddress);

  return campaignData;
};

export const getLocationData = (locationData) => {
  const location = {};
  const data = locationData.results.find((loc) =>
    loc.types.includes('route')
  )?.address_components;
  if (data) {
    for (const obj of data) {
      if (obj.types.includes('locality')) {
        location.city = obj.long_name;
      }
      if (obj.types.includes('route')) {
        location.address = obj.long_name;
      }
      if (obj.types.includes('country')) {
        location.country = obj.long_name;
      }
    }
  }

  if (!location.city) {
    location.city = 'Campaign City';
  }

  if (!location.address) {
    location.address = 'Campaign Address';
  }

  if (!location.country) {
    location.country = 'Campaign Country';
  }

  return location;
};
