import React from 'react';
import classNames from 'classnames';

export default function Icon({ icon: IconSvg, size, className }) {
  return (
    <IconSvg
      className={classNames('c-icon', {
        [`c-icon--${size}`]: size,
        [className]: className,
      })}
    />
  );
}
