export const FILE_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const MAX_FILE_SIZE = {
  IMAGE: 10,
  VIDEO: 100,
};

export const ACCEPTED_FORMATS = {
  IMAGE: 'image/x-png, image/png, image/bmp, image/jpeg, image/jpg',
  VIDEO: 'video/mp4',
};
