import React from 'react';
import spinner from '../../assets/images/spinner.gif';

const chunkSpinner = (
  <div className="hqe3svQs1weasOe69">
    <img src={spinner} alt="loading spinner" />
  </div>
);

export default chunkSpinner;
