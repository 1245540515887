import { ReactComponent as InPersonIcon } from 'assets/images/in-person-icon-red.svg';
import { ReactComponent as DigitalIcon } from 'assets/images/digital-icon-orange.svg';
import { ReactComponent as HybridIcon } from 'assets/images/hybrid-icon-blue.svg';
import messages from 'messages';

export const CAMPAIGN_CALCULATIONS = {
  CAMPAIGN_INTERVAL: '%sHrs',
  TAXES_AND_FEES_PER_USER_HOUR: 16.5,
  EYVOCATE_EARNINGS_PERCENTAGE: 60,
  EYVISOR_EARNINGS_PERCENTAGE: 70,
  SUPERVISOR_EXTRA_PERCENT_FOR_CAMPAIGN_PRICE_PER_HOUR: 15,
};

export const CAMPAIGN_FORM_TEMPLATES = {
  FROM_SCRATCH: 'fromScratch',
  FROM_TEMPLATE: 'fromTemplate',
};

export const CAMPAIGNS_LIST = {
  NUMBER_OF_CAMPAIGNS_LOADED_PER_PAGE: 20,
  NUMBER_OF_DASHBOARD_CAMPAIGNS_LOADED_PER_PAGE: 50,
};

export const CAMPAIGN_GALLERY_IMAGES_LOADED_PER_PAGE = 12;

export const CAMPAIGNS_TEMPLATES = {
  NUMBER_OF_CAMPAIGNS_TEMPLATES_LOADED_PER_PAGE: 7,
};

export const CAMPAIGN_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ACTIVE: 'active',
  POST_ACTIVE: 'post-active',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  IN_REVIEW: 'in-review',
  CHANGES_REQUESTED: 'changes-requested',
  PRE_PUBLISH: 'pre-publish',
  PUBLISHABLE: 'publishable',
};

export const RESOLVED_CAMPAIGN_STATUSES = [
  CAMPAIGN_STATUS.FINISHED,
  CAMPAIGN_STATUS.CANCELED,
];

export const CAMPAIGN_TASKS_TYPES = {
  PRE_CAMPAIGN: 'pre-campaign',
  LIVE_CAMPAIGN: 'live-campaign',
  FOLLOW_UP: 'follow-up',
};

export const CAMPAIGN_TASKS_CATEGORIES = {
  DEFAULT: 'default',
  ARRIVAL: 'arrival',
  CHECK_IN: 'check-in',
  SOCIAL: 'social',
  SURVEY: 'survey',
};

export const CAMPAIGN_TYPES = {
  IN_PERSON: 'in-person',
  DIGITAL: 'digital',
  HYBRID: 'hybrid',
};

export const CAMPAIGN_VALIDATIONS = {
  CAMPAIGN_NAME_MAX_CHARACTERS: 120,
  MIN_EYVOCATE_AGE: 18,
  MAX_PRICE_PER_HOUR: 100,
  DEFAULT_HOURS_CAMPAIGN_DURATION: 2,
  MIN_EYVOCATES_AGE: 18,
  MAX_EYVOCATE_AGE: 120,
  DEFAULT_MIN_EYVOCATE_AGE: 18,
  DEFAULT_MAX_EYVOCATE_AGE: 120,
  MAX_INTERESTS: 5,
  TIME_PICKER_INTERVALS_MINS: 15,
  MIN_MINUTES_TASK_DURATION: 15,
};

export const CAMPAIGN_SLIDER_STEPS = {
  PRICE_PER_HOUR_SLIDER_STEPS: 0.5,
  MAX_EYVOCATS_SLIDER_STEPS: 1,
  CAMPAIGN_DURATION_SLIDER_STEPS: 1,
  EYVOCATE_AGE_SLIDER_STEPS: 2,
};

export const DEMOGRAPHIC_OPTIONS = {
  GROUPS: 'groups',
  INDIVIDUAL: 'individual',
  RANDOM: 'random',

  ALL_GENDERS: 'all-genders',
  MOSTLY_MALE: 'mostly-male',
  MOSTLY_FEMALE: 'mostly-female',
  MOSTLY_CUSTOM: 'mostly-custom',
  ONLY_MALE: 'male',
  ONLY_FEMALE: 'female',
  ONLY_CUSTOM: 'custom',

  NEED_TO_HAVE: 'need_to_have',
  PREFERENCE: 'preference',

  ALL_AGES: 'all_ages',
  AGE_RANGE_18_24: 'age_range_18-24',
  AGE_RANGE_25_34: 'age_range_25-34',
  AGE_RANGE_35_44: 'age_range_35-44',
  AGE_RANGE_45_49: 'age_range_45-49',
  AGE_RANGE_50_54: 'age_range_50-54',
  AGE_RANGE_55_64: 'age_range_55-64',
  AGE_RANGE_65_ABOVE: 'age_range_65-above',
};

export const SOCIAL_POST_PREFERENCES = {
  PHOTO: 'photo',
  VIDEO: 'video',
  TEXT: 'text',
};

export const CAMPAIGN_CUSTOM_NOTIFICATIONS = {
  NUMBER_OF_NOTIFICATIONS_PER_PAGE: 15,
  BODY_MAX_NUMBER_OF_CHARACTERS: 500,
};

export const CAMPAIGN_TYPES_ICONS = {
  [CAMPAIGN_TYPES.IN_PERSON]: InPersonIcon,
  [CAMPAIGN_TYPES.HYBRID]: HybridIcon,
  [CAMPAIGN_TYPES.DIGITAL]: DigitalIcon,
};

const PRE_CAMPAIGN_TASKS = 'preCampaignTasks';
const LIVE_CAMPAIGN_TASKS = 'liveCampaignTasks';
const FOLLOW_UP_CAMPAIGN_TASKS = 'followUpTasks';

export const CAMPAIGN_TASKS_TOOLTIP = {
  [PRE_CAMPAIGN_TASKS]: messages.tasks.preCampaignTasks,
  [LIVE_CAMPAIGN_TASKS]: messages.tasks.liveCampaignTasks,
  [FOLLOW_UP_CAMPAIGN_TASKS]: messages.tasks.followUpTasks,
};

export const FORMAT_TYPES = {
  MP4: 'mp4',
};
