import React, { useMemo } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import AdvertisersPage from 'containers/AdvertisersPage/Loadable';
import CreateCampaignPage from 'containers/CreateCampaignPage/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import SingleCampaign from 'containers/SingleCampaignPage/Loadable';
import ReportsPage from 'containers/ReportsPage/Loadable';
import AdvertiserSettings from '../../containers/AdvertiserSettingsPage/Loadable';
import AdvertiserSettingsPage from 'containers/AdvertiserSettingsPage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import RegisterPage from 'containers/RegisterPage/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import {
  WELCOME,
  ADVERTISERS,
  CREATE_CAMPAIGN,
  DASHBOARD,
  DASHBOARD_CREATE_CAMPAIGN,
  ADVERTISER_SETTINGS,
  SINGLE_CAMPAIGN,
  LOGIN,
  REGISTER,
  REPORTS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ABOUT_US,
  TERMS,
  PRIVACY,
  JOBS,
  CONTACT,
  WE_LOVE_HOUSTON,
  DASHBOARD_CREATE_CAMPAIGN_OBJECTIVES,
  CAMPAIGN_SUMMARY,
  NEED_HELP,
  CREATE_SIMPLE_CAMPAIGN,
} from 'routes';
import DashboardLayout from 'components/Layout/DashboardLayout';
// import JobsPage from 'containers/JobsPage/Loadable';
// import PrivacyPolicyPage from 'containers/PrivacyPolicyPage/Loadable';
// import TermsAndConditions from 'containers/TermsAndConditions/Loadable';
// import ContactPage from 'containers/ContactPage/Loadable';
// import WeLovePage from '../WeLovePage/Loadable';
// import AboutUsPage from 'containers/AboutUsPage/Loadable';
import CampaignSummaryPage from 'containers/CampaignSummaryPage/Loadable';
import ECWCreateCampaignPage from 'containers/ECWCreateCampaignPage/Loadable';
import ECWCampaignObjectives from 'components/ECWCreateCampaign/ECWCampaignObjectives';
import NeedHelpPage from 'containers/NeedHelpPage/Loadable';
import CreateSimpleCampaignPage from 'containers/CreateSimpleCampaignPage/Loadable';

const DASHBOARD_EXCLUDED_ROUTES = [
  WELCOME,
  ADVERTISERS,
  ABOUT_US,
  JOBS,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CREATE_CAMPAIGN,
  TERMS,
  PRIVACY,
  CONTACT,
  WE_LOVE_HOUSTON,
  CAMPAIGN_SUMMARY,
  CREATE_SIMPLE_CAMPAIGN,
];
export default function Routes() {
  const location = useLocation();

  const getExactPathName = (path) => path.split('/')[1];

  const shouldRenderDashboard = useMemo(() => {
    return !DASHBOARD_EXCLUDED_ROUTES.some(
      (route) => getExactPathName(route) === getExactPathName(location.pathname)
    );
  }, [location.pathname]);

  return (
    <>
      {shouldRenderDashboard && <DashboardLayout hasNavigationMenu />}
      <Switch>
        <PrivateRoute exact path={DASHBOARD} component={Dashboard} />
        <PrivateRoute exact path={REPORTS} component={ReportsPage} />
        <PrivateRoute
          exact
          path={DASHBOARD_CREATE_CAMPAIGN}
          component={ECWCreateCampaignPage}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_CREATE_CAMPAIGN_OBJECTIVES}
          component={ECWCampaignObjectives}
        />
        {/* <PrivateRoute
          exact
          path={ECW_CREATE_CAMPAIGN_OBJECTIVES}
          component={ECWCampaignObjectives}
        /> */}
        {/* <PrivateRoute
          exact
          path={ECW_CREATE_CAMPAIGN}
          component={ECWCreateCampaignPage}
        /> */}
        <PrivateRoute exact path={SINGLE_CAMPAIGN} component={SingleCampaign} />
        <PrivateRoute
          exact
          path={ADVERTISER_SETTINGS}
          component={AdvertiserSettings}
        />
        <PrivateRoute
          exact
          path={ADVERTISER_SETTINGS}
          component={AdvertiserSettingsPage}
        />
        <Route exact path={NEED_HELP} component={NeedHelpPage} />
        <PublicRoute exact path={ADVERTISERS} component={AdvertisersPage} />
        <Route exact path={WELCOME}>
          <Redirect to={LOGIN} />
        </Route>
        <PublicRoute
          exact
          path={CREATE_CAMPAIGN}
          component={CreateCampaignPage}
        />
        <PublicRoute exact path={LOGIN} component={LoginPage} />
        <PublicRoute exact path={REGISTER} component={RegisterPage} />
        <PublicRoute
          exact
          path={FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />
        <PublicRoute
          exact
          path={RESET_PASSWORD}
          component={ResetPasswordPage}
        />
        <PublicRoute
          exact
          path={CREATE_SIMPLE_CAMPAIGN}
          component={CreateSimpleCampaignPage}
        />
        {/* <PublicRoute exact path={JOBS} component={JobsPage} />
        <PublicRoute exact path={PRIVACY} component={PrivacyPolicyPage} />
        <PublicRoute exact path={TERMS} component={TermsAndConditions} />
        <PublicRoute exact path={CONTACT} component={ContactPage} /> */}

        <Route path={CAMPAIGN_SUMMARY} component={CampaignSummaryPage} />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}
