import config from 'config';
import { push } from 'connected-react-router';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import globalMessages from 'messages';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SINGLE_CAMPAIGN, LOGIN } from 'routes';
import { format } from 'util';
import { formatLocationData } from 'utils/googleLocations';
import { removeItem, setItem } from 'utils/localStorage';
import request from 'utils/request';
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPES,
  ENDPOINTS,
  GOOGLE_MAPS,
} from '../../constants';
import {
  addPaymentMethodError,
  addPaymentMethodSuccess,
  createCampaignError,
  createCampaignSuccess,
  deletePaymentMethodError,
  deletePaymentMethodSuccess,
  fetchAuthenticatedAdvertiserSuccess,
  fetchCommonDataSuccess,
  fetchCampaignCommonDataSuccess,
  fetchEyVocateLocationsSuccess,
  fetchEyVocateLocationsError,
  fetchPaymentMethodsSuccess,
  logoutSuccess,
  setDefaultPaymentMethodError,
  setDefaultPaymentMethodSuccess,
  setToken,
  verifyAccountSuccess,
  updatePartialCompanyInfoSuccess,
  updatePartialCompanyInfoError,
  deleteTaskTemplateSuccess,
  addSocialAccountSuccess,
  verifySocialAccountSuccess,
  fetchAdvertisersSocialAccountsSuccess,
  fetchAdvertisersSocialAccountsError,
  deleteSocialAccountSuccess,
  fetchCampaignTemplatesSuccess,
} from './actions';
import {
  ADD_PAYMENT_METHOD_REQUEST,
  CREATE_CAMPAIGN_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  FETCH_AUTHENTICATED_ADVERTISER_REQUEST,
  FETCH_COMMON_DATA_REQUEST,
  FETCH_CAMPAIGN_COMMON_DATA_REQUEST,
  FETCH_PAYMENT_METHODS_REQUEST,
  LOGOUT_REQUEST,
  SET_DEFAULT_PAYMENT_METHOD_REQUEST,
  VERIFY_ACCOUNT_REQUEST,
  RESEND_VERIFICATION_LINK,
  UPDATE_COMPANY_INFO_REQUEST,
  FETCH_EYVOCATE_LOCATIONS_ATTEMPT,
  DELETE_TASK_TEMPLATE_REQUEST,
  ADD_SOCIAL_ACCOUNT_REQUEST,
  VERIFY_SOCIAL_ACCOUNT_REQUEST,
  FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT,
  DELETE_SOCIAL_ACCOUNT_REQUEST,
  FETCH_CAMPAIGN_TEMPLATES_REQUEST,
} from './constants';
import { handleBadRequestAndUnauthorised } from '../../utils/handleBadRequestAndUnauthorised';
import toasterVariants from '../../constants/toasterVariants';
import messages from './../../messages';

export function* fetchAdvertiser({ impersonateToken, verificationToken }) {
  try {
    if (impersonateToken) {
      yield call(setItem, 'token', impersonateToken);
      yield put(setToken(impersonateToken));
    }

    const advertiser = yield call(request, {
      url: ENDPOINTS.AUTH_ME,
      method: 'get',
    });

    if (verificationToken) {
      yield call(verifyAccount, { token: verificationToken });
    }
    yield put(fetchAuthenticatedAdvertiserSuccess(advertiser));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error?.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error?.data?.statusCode);
  }
}

export function* logout({ mobile }) {
  try {
    yield put(logoutSuccess(mobile));
    yield call(removeItem, 'token');
    yield put(push(LOGIN));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* fetchCommonData() {
  try {
    const commonData = yield call(request, {
      url: ENDPOINTS.COMMON_DATA,
      method: 'get',
    });
    yield put(fetchCommonDataSuccess(commonData));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

const getActiveAdvertiser = (state) => state.app.advertiser;

export function* fetchCampaignCommonData() {
  try {
    const advertiser = yield select(getActiveAdvertiser);

    const campaignCommonData = yield call(request, {
      url: advertiser
        ? ENDPOINTS.CAMPAIGN_COMMON_DATA
        : ENDPOINTS.CAMPAIGN_COMMON_DATA_NOT_AUTHENTICATED,
      method: 'get',
    });
    yield put(fetchCampaignCommonDataSuccess(campaignCommonData));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* fetchEyVocateLocations(inputFieldValue) {
  try {
    const eyVocateLocations = yield call(request, {
      url: format(
        ENDPOINTS.EYVOCATE_DEMOGRAPHICS_LOCATIONS,
        inputFieldValue.inputFieldValue
      ),
      method: 'get',
    });
    yield put(fetchEyVocateLocationsSuccess(eyVocateLocations));
  } catch (error) {
    yield put(fetchEyVocateLocationsError(error));
    console.error(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* createCampaign({ campaignData }) {
  try {
    if (campaignData.logo) {
      const logoFormData = new FormData();
      logoFormData.append('logo', campaignData.logo);
      const campaignLogo = yield call(request, {
        url: ENDPOINTS.CAMPAIGN_LOGO,
        method: 'post',
        data: logoFormData,
      });
      campaignData.logo = campaignLogo.key;
    }
    if (campaignData.video) {
      const videoFormData = new FormData();
      videoFormData.append('video', campaignData.video);
      const campaignVideo = yield call(request, {
        url: ENDPOINTS.CAMPAIGN_VIDEO,
        method: 'post',
        data: videoFormData,
      });
      campaignData.video = campaignVideo.key;
    }
    const centerPointLocationData =
      campaignData.type !== CAMPAIGN_TYPES.DIGITAL
        ? yield call(request, {
            url: format(
              GOOGLE_MAPS.GEOCODE_API_URL,
              campaignData.location.centerPoint.latitude,
              campaignData.location.centerPoint.longitude,
              config.googleMaps.apiKey
            ),
            method: 'get',
            withoutToken: true,
            allowHeaders: false,
          })
        : null;
    const newData =
      campaignData.type === CAMPAIGN_TYPES.DIGITAL
        ? campaignData
        : yield call(formatLocationData, campaignData, centerPointLocationData);
    const { id, status } = yield call(request, {
      url: ENDPOINTS.CAMPAIGNS,
      method: 'post',
      data: newData,
    });
    yield put(createCampaignSuccess());
    yield put(
      enqueueSnackbar({
        message: globalMessages.campaignSuccessfullyCreated,
        variant: toasterVariants.success,
      })
    );
    yield put(push(SINGLE_CAMPAIGN.replace(':id', id)));
    if (status !== CAMPAIGN_STATUS.IN_REVIEW) return;
    if (status === CAMPAIGN_STATUS.IN_REVIEW) {
      yield put(
        enqueueSnackbar({
          message: globalMessages.yourCampaignIsInReview,
          variant: toasterVariants.info,
        })
      );
    }
  } catch (error) {
    yield put(createCampaignError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* fetchPaymentMethods() {
  try {
    const paymentMethods = yield call(request, {
      url: ENDPOINTS.PAYMENT_METHODS,
      method: 'get',
    });
    yield put(fetchPaymentMethodsSuccess(paymentMethods));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* addPaymentMethod({
  paymentProviderPaymentMethodId,
  meta: { onSuccess, onError },
}) {
  try {
    const paymentMethod = yield call(request, {
      url: ENDPOINTS.PAYMENT_METHODS,
      method: 'post',
      data: { stripePaymentMethodId: paymentProviderPaymentMethodId },
    });
    yield put(
      enqueueSnackbar({
        message: messages.paymentAddedSuccessMessage,
        variant: toasterVariants.success,
      })
    );
    yield put(addPaymentMethodSuccess(paymentMethod));
    onSuccess(paymentMethod);
  } catch (error) {
    onError(error);
    yield put(addPaymentMethodError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    if (
      error.data?.message !==
      'Payment provider could not process payment method'
    ) {
      handleBadRequestAndUnauthorised(error.data?.statusCode);
    }
  }
}

export function* setDefaultPaymentMethod({ paymentMethod }) {
  try {
    yield call(request, {
      url: format(ENDPOINTS.DEFAULT_PAYMENT_METHOD, paymentMethod.id),
      method: 'post',
    });
    yield put(
      enqueueSnackbar({
        message: messages.paymentSetDefaultSuccessMessage,
        variant: toasterVariants.success,
      })
    );
    yield put(setDefaultPaymentMethodSuccess(paymentMethod));
  } catch (error) {
    yield put(setDefaultPaymentMethodError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* deletePaymentMethod({ paymentMethod }) {
  try {
    yield call(request, {
      url: format(ENDPOINTS.PAYMENT_METHOD, paymentMethod.id),
      method: 'delete',
    });

    yield put(
      enqueueSnackbar({
        message: messages.paymentDeletedSuccessMessage,
        variant: toasterVariants.success,
      })
    );
    yield put(deletePaymentMethodSuccess(paymentMethod));
  } catch (error) {
    yield put(deletePaymentMethodError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}
export function* verifyAccount({ token }) {
  try {
    const { accessToken } = yield call(request, {
      url: ENDPOINTS.ACCOUNT_VERIFICATION,
      method: 'post',
      data: { token },
    });

    yield put(verifyAccountSuccess());
    yield call(setItem, 'token', accessToken);
    yield put(setToken(accessToken));
    yield put(
      enqueueSnackbar({
        message: globalMessages.verifyAccountSuccessfully,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: globalMessages.verifyAccountFailed,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* resendLink() {
  try {
    yield call(request, {
      url: ENDPOINTS.RESEND_VERIFICATION_LINK,
      method: 'get',
    });
    yield put(
      enqueueSnackbar({
        message: globalMessages.verificationLinkSent,
        variant: toasterVariants.info,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* updatePartialCompanyInfo({ companyInfo }) {
  try {
    const advertiser = yield call(request, {
      url: ENDPOINTS.UPDATE_PARTIAL_COMPANY_INFO,
      method: 'put',
      data: companyInfo,
    });

    yield put(updatePartialCompanyInfoSuccess(advertiser));
  } catch (error) {
    yield put(updatePartialCompanyInfoError());
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* addSocialAccountRequest({ socialAccountInfo }) {
  try {
    const socialAccount = yield call(request, {
      url: ENDPOINTS.ADD_SOCIAL_ACCOUNT,
      method: 'post',
      data: socialAccountInfo,
    });

    yield put(addSocialAccountSuccess(socialAccount));
    yield put(
      enqueueSnackbar({
        message: messages.socialAccountAdded,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* deleteTaskTemplateRequest({ id }) {
  try {
    yield call(request, {
      url: `/admin/task-templates/delete/${id}`,
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: messages.taskDeleted,
        variant: toasterVariants.success,
      })
    );
    yield put(deleteTaskTemplateSuccess(id));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* verifySocialAccount({ socialAccountInfo }) {
  try {
    const socialAccount = yield call(request, {
      url: ENDPOINTS.VERIFY_SOCIAL_ACCOUNT,
      method: 'post',
      data: socialAccountInfo,
    });

    yield put(verifySocialAccountSuccess(socialAccount));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

const getSocialAccounts = (state) => state.app.advertiser.socialAccounts;

export function* fetchAdvertisersSocialAccounts() {
  try {
    const newSocialAccounts = yield call(request, {
      url: ENDPOINTS.ADD_SOCIAL_ACCOUNT,
      method: 'get',
    });

    const socialAccounts = yield select(getSocialAccounts);

    if (socialAccounts.length !== newSocialAccounts.length) {
      yield put(fetchAdvertisersSocialAccountsSuccess(newSocialAccounts));
    }
  } catch (error) {
    yield put(fetchAdvertisersSocialAccountsError(error));
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export function* deleteSocialAccountRequest({ id }) {
  try {
    yield call(request, {
      url: `/business-representative/social-account/${id}`,
      method: 'delete',
    });
    yield put(deleteSocialAccountSuccess(id));
    yield put(
      enqueueSnackbar({
        message: messages.socialAccountDeleted,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* fetchCampaignTemplatesRequest({ page, perPage, search }) {
  try {
    const campaignTemplates = yield call(request, {
      url: format(ENDPOINTS.CAMPAIGN_TEMPLATES, page, perPage, search),
      method: 'get',
    });
    yield put(fetchCampaignTemplatesSuccess(campaignTemplates));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );

    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export default function* appSaga() {
  yield takeLatest(FETCH_AUTHENTICATED_ADVERTISER_REQUEST, fetchAdvertiser);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(FETCH_COMMON_DATA_REQUEST, fetchCommonData);
  yield takeLatest(FETCH_CAMPAIGN_COMMON_DATA_REQUEST, fetchCampaignCommonData);
  yield takeLatest(FETCH_EYVOCATE_LOCATIONS_ATTEMPT, fetchEyVocateLocations);
  yield takeLatest(CREATE_CAMPAIGN_REQUEST, createCampaign);
  yield takeLatest(FETCH_PAYMENT_METHODS_REQUEST, fetchPaymentMethods);
  yield takeLatest(ADD_PAYMENT_METHOD_REQUEST, addPaymentMethod);
  yield takeLatest(SET_DEFAULT_PAYMENT_METHOD_REQUEST, setDefaultPaymentMethod);
  yield takeLatest(DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethod);
  yield takeLatest(VERIFY_ACCOUNT_REQUEST, verifyAccount);
  yield takeLatest(RESEND_VERIFICATION_LINK, resendLink);
  yield takeLatest(UPDATE_COMPANY_INFO_REQUEST, updatePartialCompanyInfo);
  yield takeLatest(DELETE_TASK_TEMPLATE_REQUEST, deleteTaskTemplateRequest);
  yield takeLatest(ADD_SOCIAL_ACCOUNT_REQUEST, addSocialAccountRequest);
  yield takeLatest(VERIFY_SOCIAL_ACCOUNT_REQUEST, verifySocialAccount);
  yield takeLatest(
    FETCH_ADVERTISER_SOCIAL_ACCOUNTS_ATTEMPT,
    fetchAdvertisersSocialAccounts
  );
  yield takeLatest(DELETE_SOCIAL_ACCOUNT_REQUEST, deleteSocialAccountRequest);
  yield takeLatest(
    FETCH_CAMPAIGN_TEMPLATES_REQUEST,
    fetchCampaignTemplatesRequest
  );
}
