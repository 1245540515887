import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

export const ErrorBoundaryView = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <a href="mailto:support@eycrowd.com">
        {formatMessage(messages.reportFeedbackButton)}
      </a>
      <a href="/">{formatMessage(messages.backLink)}</a>
    </>
  );
};
