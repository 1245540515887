import {
  ECW_ADVERTISER_GALLERY_SUCCESS,
  ADVERTISER_CAMPAIGN_GALLERY_SUCCESS,
  DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE,
  PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE,
  CLEAR_UPLOAD_MODAL,
  FETCH_DUPLICATE_CAMPAIGN_REQUEST,
  FETCH_DUPLICATE_CAMPAIGN_SUCCESS,
  POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS,
  POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR,
  POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  DELETE_GALLERY_ENTRIES_REQUEST,
  DELETE_GALLERY_ENTRIES_ERROR,
  DELETE_GALLERY_ENTRIES_SUCCESS,
  ADVERTISER_CAMPAIGN_GALLERY_REQUEST,
} from 'containers/ECWCreateCampaignPage/constants';
import produce from 'immer';

export const initialState = {
  advertiserGallery: {
    data: null,
  },
  advertiserCampaignGallery: {
    loading: false,
    data: null,
  },
  draftCampaignAttempt: false,
  publishCampaignAttempt: false,
  duplicateCampaign: null,
  isDuplicateCampaignLoading: false,
  mediaUploading: false,
  deletingGalleryEntries: false,
};

const advertiserGalleryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ECW_ADVERTISER_GALLERY_SUCCESS:
        draft.advertiserGallery = {
          data: action.advertiserGallery.data,
        };
        break;
      case ADVERTISER_CAMPAIGN_GALLERY_REQUEST:
        draft.advertiserCampaignGallery.loading = true;
        break;
      case ADVERTISER_CAMPAIGN_GALLERY_SUCCESS:
        draft.advertiserCampaignGallery = {
          data: action.advertiserCampaignGallery.data,
          loading: false,
        };
        break;
      case POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST:
        draft.mediaUploading = true;
        break;
      case POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST:
        draft.mediaUploading = true;
        break;
      case POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS:
        draft.mediaUploading = false;
        draft.advertiserGallery.data = [
          ...draft.advertiserGallery.data,
          action.payload,
        ];
        break;
      case POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR:
        draft.mediaUploading = false;
        break;
      case DRAFT_CAMPAIGN_INFO_ATTEMPT_TOGGLE:
        return {
          ...state,
          draftCampaignAttempt: !state.draftCampaignAttempt,
        };
      case PUBLISH_CAMPAIGN_INFO_ATTEMPT_TOGGLE:
        return {
          ...state,
          publishCampaignAttempt: !state.publishCampaignAttempt,
        };
      case CLEAR_UPLOAD_MODAL:
        return {
          ...state,
          uploadedLogo: {
            ...initialState.uploadedLogo,
          },
          uploadedVideo: {
            ...initialState.uploadedVideo,
          },
        };
      case FETCH_DUPLICATE_CAMPAIGN_REQUEST:
        draft.isDuplicateCampaignLoading = true;
        break;
      case FETCH_DUPLICATE_CAMPAIGN_SUCCESS:
        draft.isDuplicateCampaignLoading = false;
        draft.duplicateCampaign = action.duplicateCampaign;
        break;
      case DELETE_GALLERY_ENTRIES_REQUEST:
        draft.deletingGalleryEntries = true;
        break;
      case DELETE_GALLERY_ENTRIES_SUCCESS:
        draft.deletingGalleryEntries = false;
        draft.advertiserGallery.data = draft.advertiserGallery.data.filter(
          ({ id }) => !action.galleryEntryIds.includes(id)
        );
        break;
      case DELETE_GALLERY_ENTRIES_ERROR:
        draft.deletingGalleryEntries = false;
        break;
      default:
        return state;
    }
  });

export default advertiserGalleryReducer;
