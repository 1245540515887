import * as Yup from 'yup';
import messages from '../messages';

Yup.setLocale({
  mixed: {
    required: messages.required,
    oneOf: messages.oneOf,
  },
  number: {
    integer: messages.integer,
    positive: messages.positive,
    moreThan: messages.moreThan,
    min: messages.minNumber,
  },
  date: {
    min: messages.minDate,
    max: messages.maxDate,
  },
  string: {
    email: messages.email,
    min: messages.min,
    max: messages.max,
    matches: messages.matches,
  },
  array: {
    max: messages.arrayMax,
  },
});

export default Yup;
