import React from 'react';
import styles from './Error.module.scss';
import classNames from 'classnames';

const Error = ({ children, alt }) => {
  return (
    <div
      className={classNames(styles.error, {
        [styles.altError]: alt,
      })}
    >
      {children}
    </div>
  );
};

export default Error;
