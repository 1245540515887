import messages from 'messages';

export const BUSINESS_TYPES = {
  LLC: 'llc',
  C_CORP: 'c-corp',
  S_CORP: 's-corp',
  PARTNERSHIP: 'partnership',
  SOLE_PROPRIETORSHIP: 'sole-proprietorship',
};

export const BUSINESS_TYPES_OPTIONS = [
  {
    id: BUSINESS_TYPES.LLC,
    label: messages.businessTypes.llc,
  },
  {
    id: BUSINESS_TYPES.C_CORP,
    label: messages.businessTypes['c-corp'],
  },
  {
    id: BUSINESS_TYPES.S_CORP,
    label: messages.businessTypes['s-corp'],
  },
  {
    id: BUSINESS_TYPES.PARTNERSHIP,
    label: messages.businessTypes.partnership,
  },
  {
    id: BUSINESS_TYPES.SOLE_PROPRIETORSHIP,
    label: messages.businessTypes['sole-proprietorship'],
  },
];
