import React from 'react';
import styles from './Spinner.module.scss';
import Spinner from 'assets/images/spinner.gif';

const LoadingSpinner = () => {
  return (
    <div className={styles.spinnerWrapper}>
      <img src={Spinner} alt="Spinner" />
    </div>
  );
};

export default LoadingSpinner;
