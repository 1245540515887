export const GOOGLE_MAPS = {
  GEOCODE_API_URL:
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=%s,%s&key=%s&language=en',
  DEFAULT_MAP_CENTER_LATLNG: {
    lat: 40.32453433301835,
    lng: -101.02972637991537,
  },
  LOCATION_TYPE_COUNTRY: 'country',
  LOCATION_TYPE_LOCALITY: 'locality',
  LIBRARIES: ['places', 'drawing', 'geometry'],
};
