import React, {
  forwardRef,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import { Modal } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import ContentBox from 'components/ContentBox';
import { useIntl } from 'react-intl';
import styles from './ConfirmationModal.module.scss';
import messages from 'messages';

const ConfirmationModalContext = createContext({
  openModal: (
    message,
    onConfirm,
    confirmButtonText,
    onCancel,
    cancelButtonText
  ) => {},
});

export function ConfirmationModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [cancelButtonText, setCancelButtonText] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [onCancel, setOnCancel] = useState(() => () => {});

  const { formatMessage } = useIntl();

  const openModal = (
    message,
    onConfirm,
    confirmButtonText = formatMessage(messages.confirmationModal.confirm),
    onCancel = () => {},
    cancelButtonText = formatMessage(messages.confirmationModal.cancel)
  ) => {
    setIsOpen(true);
    setMessage(message);
    setConfirmButtonText(confirmButtonText);
    setCancelButtonText(cancelButtonText);
    setOnConfirm(() => onConfirm);
    setOnCancel(() => onCancel);
  };

  const closeModal = () => {
    setIsOpen(false);
    setMessage('');
    setConfirmButtonText('');
    setCancelButtonText('');
    setOnConfirm(() => () => {});
    setOnCancel(() => () => {});
  };

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    closeModal();
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    closeModal();
  }, [onCancel]);

  return (
    <ConfirmationModalContext.Provider value={{ openModal }}>
      {children}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={closeModal}
        message={message}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </ConfirmationModalContext.Provider>
  );
}

const ConfirmationModalContent = forwardRef(
  (
    { message, confirmButtonText, cancelButtonText, onConfirm, onCancel },
    ref
  ) => {
    return (
      <ContentBox className={styles.wrapper}>
        {message && <h6 className={styles.modalText}>{message}</h6>}
        <div className={styles.buttonsWrapper}>
          <Button isLarge alt title={confirmButtonText} onClick={onConfirm} />
          <Button isLarge alt title={cancelButtonText} onClick={onCancel} />
        </div>
      </ContentBox>
    );
  }
);

const ConfirmationModal = ({
  isOpen,
  onClose,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal className={styles.container} open={isOpen} onClose={onClose}>
      <ConfirmationModalContent
        message={message}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export const useConfirmationModal = () => {
  const { openModal } = useContext(ConfirmationModalContext);
  return [openModal];
};

export default ConfirmationModal;
