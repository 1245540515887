import produce from 'immer';
import { FREE_SLOTS_SUCCESS } from './constants';

export const initialState = {
  freeSlots: null,
};

/* eslint-disable default-case */
const freeSlotsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FREE_SLOTS_SUCCESS:
        draft.freeSlots = action.data;
        break;
    }
  });

export default freeSlotsReducer;
