import { defineMessages } from 'react-intl';

export const scope = 'error_page';

export default defineMessages({
  reportFeedbackButton: {
    id: `${scope}.button.report_feedback`,
    defaultMessage:
      'In case error persists, please contact us at support@eycrowd.com',
  },
  backLink: {
    id: `${scope}.link.back`,
    defaultMessage: 'Go back',
  },
});
