import Icon from 'components/Icon';
import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { useEffect } from 'react';
import styles from './ECWCampaignObjectives.module.scss';
import { ReactComponent as OnlineAttentionSVG } from '../../../assets/images/online-attention.svg';
import { ReactComponent as OnlineEngagementSVG } from '../../../assets/images/online-engagement.svg';
import { ReactComponent as OnlineTrafficSVG } from '../../../assets/images/online-traffic.svg';
import { ReactComponent as InPersonAttentionSVG } from '../../../assets/images/in-person-attention.svg';
import { ReactComponent as InPersonEngagementSVG } from '../../../assets/images/in-person-engagement.svg';
import { ReactComponent as InPersonTrafficSVG } from '../../../assets/images/in-person-traffic.svg';
import { ReactComponent as MediaBlack } from '../../../assets/images/media-black.svg';
import { ReactComponent as PhoneBlack } from '../../../assets/images/phone-black.svg';
import { useHistory } from 'react-router';
import { DASHBOARD_CREATE_CAMPAIGN } from 'routes';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import objectiveReducer from './reducer';
import objectiveSaga from './saga';
import { useDispatch, useSelector } from 'react-redux';
import { makeFetchObjectivesAttempt, makeSelectObjectives } from './selectors';
import { fetchObjectivesRequest } from './actions';
import Spinner from 'assets/images/spinner.gif';
import Tooltip from 'components/Tooltip';
import Banner from '../Banner';
import { CAMPAIGN_OBJECTIVES } from 'constants/titles';

const ECWCampaignObjectives = () => {
  useInjectSaga({ key: 'objectiveList', saga: objectiveSaga });
  useInjectReducer({ key: 'objectiveList', reducer: objectiveReducer });
  const history = useHistory();
  const objectives = useSelector(makeSelectObjectives());
  const fetchObjectivesAttempt = useSelector(makeFetchObjectivesAttempt());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchObjectivesRequest());
  }, [dispatch]);

  useEffect(() => {
    document.title = CAMPAIGN_OBJECTIVES;
  }, []);

  const renderObjectives = () => {
    return objectives
      .sort((a, b) => a.id - b.id)
      .map((category) => (
        <div key={category.id} className={styles.contentContainer}>
          <p key={category.id} className={styles.headerTitle}>
            {category.name}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {category.objectives
              .sort((a, b) => a.id - b.id)
              .map((objective) => {
                return (
                  <ObjectiveItem
                    key={`${category.id}-${objective.id}`}
                    text={objective.title}
                    info={objective.info}
                    icon={setItemImage(objective.icon)}
                    onPress={() =>
                      history.push(DASHBOARD_CREATE_CAMPAIGN, {
                        campaignType: objective.description,
                        objective: objective,
                      })
                    }
                  />
                );
              })}
          </div>
        </div>
      ));
  };

  const setItemImage = (icon) => {
    switch (icon) {
      case 'in-person-attention':
        return InPersonAttentionSVG;
      case 'online-attention':
        return OnlineAttentionSVG;
      case 'in-person-traffic':
        return InPersonTrafficSVG;
      case 'online-traffic':
        return OnlineTrafficSVG;
      case 'in-person-engagement':
        return InPersonEngagementSVG;
      case 'online-engagement':
        return OnlineEngagementSVG;
      default:
        return InPersonAttentionSVG;
    }
  };

  return (
    <main className={styles.container}>
      <div className={styles.bannerContainer}>
        <Banner />
      </div>
      <DashboardLayout>
        <h3>Campaign Objective</h3>
        <p>
          Get promoted by people who love your brand. Unlimited possibilities.
        </p>
        <div className={styles.objectivesWrapper}>
          {fetchObjectivesAttempt ? (
            <img src={Spinner} alt="Spinner" style={{ marginLeft: '120px' }} />
          ) : (
            <div className={styles.objectivesContainer}>
              {renderObjectives()}
            </div>
          )}
        </div>
        {!fetchObjectivesAttempt && (
          <div className={styles.bottomRow}>
            <div
              className={styles.actionCard}
              onClick={() =>
                history.push(DASHBOARD_CREATE_CAMPAIGN, {
                  campaignType: 'hybrid',
                })
              }
            >
              <p className={styles.actionCardText}>Create Hybrid Campaign</p>
              <Icon icon={MediaBlack} size="md24" />
            </div>
            <div style={{ width: '20px' }} />
            <div
              className={styles.actionCard}
              onClick={() => history.push(DASHBOARD_CREATE_CAMPAIGN)}
            >
              <p className={styles.actionCardText}>Create Custom Campaign</p>
              <Icon icon={PhoneBlack} size="md24" />
            </div>
          </div>
        )}
      </DashboardLayout>
    </main>
  );
};

const ObjectiveItem = ({ text, info, icon, onPress }) => {
  return (
    <div className={styles.objectiveContainer} onClick={onPress}>
      {info && info !== '' && (
        <Tooltip className={styles.tooltip} label={info} />
      )}
      <Icon icon={icon} size="xl" />
      <p className={styles.objectiveText}>{text}</p>
    </div>
  );
};

export default ECWCampaignObjectives;
