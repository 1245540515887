export const WEB_SOCKET_MESSAGE = {
  TEST: 'test',
  USER: {
    CAMPAIGN: {
      STATUS_CHANGED: 'user_campaign_status_changed',
      START_DATE_CHANGED: 'user_campaign_start_date_changed',
      END_DATE_CHANGED: 'user_campaign_end_date_changed',
    },
    CAMPAIGN_USER: {
      OUT_OF_POLYGON: 'user_campaign_user_out_of_polygon',
    },
  },
  ADMIN: {
    CAMPAIGN: {
      STATUS_CHANGED: 'admin_campaign_status_changed',
    },
  },
  ADVERTISER: {
    CAMPAIGN: {
      CAMPAIGN_ROOM_NAME:
        'advertiser_{advertiser_id}_campaign_{campaign_id}_room',
      CAMPAIGN_CREATE_ROOM: 'advertiser_campaign_create_room',
      CAMPAIGN_DESTROY_ROOM: 'advertiser_campaign_destroy_room',
      ADVERTISER_ROOM_NAME:
        'advertiser_{advertiser_id}_company_{company_id}_room',
      ADVERTISER_CREATE_ROOM: 'advertiser_company_create_room',
      ADVERTISER_DESTROY_ROOM: 'advertiser_company_destroy_room',
      STATUS_CHANGED: 'advertiser_campaign_status_changed',
      USER: {
        STATUS_CHANGED: 'advertiser_campaign_user_status_changed',
        JOINED: 'advertiser_campaign_user_joined',
        LEFT: 'advertiser_campaign_user_left',
        MEDIA_UPLOADED: 'advertiser_campaign_media_uploaded',
      },
    },
  },
};
