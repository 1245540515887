import DropdownMenu from 'components/DropdownMenu';
import { logout } from 'containers/App/actions';
import {
  makeSelectAdvertiser,
  makeSelectMobile,
} from 'containers/App/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrowIcon from 'assets/images/arrow.png';
import styles from './DashboardLayout.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';

function DashboardHeader() {
  const { formatMessage } = useIntl();
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const advertiser = useSelector(makeSelectAdvertiser());
  const mobile = useSelector(makeSelectMobile());

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout(mobile));
  };

  return (
    <div className={styles.header}>
      <DropdownMenu
        toggleParentChevronDirection={() =>
          setIsDropdownMenuOpen(!isDropdownMenuOpen)
        }
        buttonContent={
          <div className={styles.dropdownToggler}>
            <span className={styles.headerCompanyName}>
              {advertiser?.company.name}
            </span>
            <img
              className={
                isDropdownMenuOpen ? styles.arrowIconUp : styles.arrowIconDown
              }
              src={arrowIcon}
              alt=""
            />
          </div>
        }
      >
        <div className={styles.dropdownWrapper}>
          <DropdownMenu.Item>
            <button className={styles.dropdownButton} onClick={handleLogout}>
              {formatMessage(messages.logOut)}
            </button>
          </DropdownMenu.Item>
        </div>
      </DropdownMenu>
    </div>
  );
}

export default DashboardHeader;
