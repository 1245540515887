import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { InputField } from 'components/Forms';
import ContentBox from 'components/ContentBox';
import Button from 'components/Buttons/Button';
import styles from './PaymentMethodModal.module.scss';
import { useDispatch } from 'react-redux';
import { addPaymentMethod } from 'containers/App/actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import globalMessages from 'messages';
import { addPaymentMethodSchema } from './validations';
import GridHalves from 'components/Layout/GridHalves';
import Icon from 'components/Icon';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import { useIntl } from 'react-intl';
import messages from './messages';

const STRIPE_ELEMENTS_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#131415',
      fontFamily: 'Avenir Next',
      letterSpacing: '-0.5px',
      height: '1.5em',
      '::placeholder': {
        color: '#b8b8b8',
      },
    },
    invalid: {},
  },
};

export default function PaymentMethodForm({ onSubmit, onAddPaymentSuccess }) {
  const { formatMessage } = useIntl();
  const stripe = useStripe();
  const stripeElements = useElements();
  const dispatch = useDispatch();

  const [isPending, setIsPending] = useState(false);
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState(false);

  const handleSubmit = async (data) => {
    if (!stripe || !stripeElements) {
      return;
    }
    setIsPending(true);

    const cardNumberElement = stripeElements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: data.cardHolder,
        address: {
          line1: data.address,
          city: data.city,
          postal_code: data.zip,
          state: data.state,
        },
      },
    });

    if (error) {
      dispatch(
        enqueueSnackbar({
          message: globalMessages.paymentProviderUnavailable,
        })
      );
      return;
    }

    const onSuccess = () => {
      setIsPending(false);
      onSubmit();
      onAddPaymentSuccess && onAddPaymentSuccess();
    };
    const onError = () => {
      setIsPending(false);
    };
    dispatch(addPaymentMethod(paymentMethod.id, onSuccess, onError));
  };

  return (
    <ContentBox className={styles.modal}>
      <h6 className={styles.modalTitle}>
        {formatMessage(messages.modalTitle)}
      </h6>
      <Formik
        initialValues={{
          cardHolder: '',
          address: '',
          city: '',
          state: '',
          zip: '',
        }}
        validationSchema={addPaymentMethodSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form>
            <InputField
              type="text"
              name="cardHolder"
              label={formatMessage(messages.cardHolderLabel)}
              placeholder={formatMessage(messages.cardHolderPlaceholder)}
              autoFocus
              required
            />
            <div className={styles.cardInfoFieldGroup}>
              <label className={styles.label}>
                {formatMessage(messages.label)}
                <Icon icon={InfoIcon} size="sm" />
              </label>
              <CardNumberElement
                onChange={(e) => setIsCardNumberValid(!e.error && e.complete)}
                options={STRIPE_ELEMENTS_OPTIONS}
                className={styles.inputField}
              />
              <GridHalves>
                <CardExpiryElement
                  onChange={(e) => setIsCardExpiryValid(!e.error && e.complete)}
                  options={STRIPE_ELEMENTS_OPTIONS}
                  className={styles.inputField}
                />
                <CardCvcElement
                  onChange={(e) => setIsCardCvcValid(!e.error && e.complete)}
                  options={STRIPE_ELEMENTS_OPTIONS}
                  className={styles.inputField}
                />
              </GridHalves>
            </div>
            <InputField
              type="text"
              name="address"
              label={formatMessage(messages.billingAdressLabel)}
              placeholder={formatMessage(messages.billingAdressPlaceholder)}
              required
            />
            <InputField
              type="text"
              name="city"
              label={formatMessage(messages.city)}
              placeholder={formatMessage(messages.city)}
              required
              labelHidden
            />
            <GridHalves>
              <InputField
                type="text"
                name="state"
                label={formatMessage(messages.state)}
                placeholder={formatMessage(messages.state)}
                required
                labelHidden
              />
              <InputField
                type="text"
                name="zip"
                label={formatMessage(messages.zipCode)}
                placeholder={formatMessage(messages.zipCode)}
                required
                labelHidden
              />
            </GridHalves>

            <Button
              disabled={
                !stripe ||
                !stripeElements ||
                !isCardNumberValid ||
                !isCardExpiryValid ||
                !isCardCvcValid ||
                !dirty ||
                !isValid ||
                isPending
              }
              alt
              type="submit"
              title={formatMessage(messages.addPayment)}
              className={styles.modalSubmitButton}
            />
            <p className={styles.additionalInfoText}>
              {formatMessage(messages.stripeText)}
            </p>
          </Form>
        )}
      </Formik>
    </ContentBox>
  );
}
