import { createSelector } from 'reselect';
import { initialState } from './reducer';

const objectiveList = (state) => state.objectiveList || initialState;

const makeSelectObjectives = () =>
  createSelector(objectiveList, (substate) => substate.objectivesByCategories);

const makeFetchObjectivesAttempt = () =>
  createSelector(objectiveList, (substate) => substate.fetchObjectives.attempt);

export { makeSelectObjectives, makeFetchObjectivesAttempt };
