import React from 'react';
import styles from './GridHalves.module.scss';

function GridHalves({ children, isDisabled }) {
  return (
    <div
      className={`${styles.container} ${
        isDisabled ? styles.cursorDisabled : ''
      }`}
    >
      {children}
    </div>
  );
}

export default GridHalves;
