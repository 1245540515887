import React, { useEffect, useState } from 'react';
import appReducer from 'containers/App/reducer';
import dashboardReducer from 'containers/Dashboard/reducer';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectAdvertiser,
  makeSelectToken,
} from 'containers/App/selectors';
import config from 'config';
import io from 'socket.io-client';
import { WEB_SOCKET_MESSAGE } from './messages';
import {
  fetchSingleCampaignGalleryRequest,
  wsCampaignStatusChanged,
} from 'containers/SingleCampaignPage/actions';

const WebsocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = useSelector(makeSelectToken());
  const advertiser = useSelector(makeSelectAdvertiser());

  useInjectReducer({ key: 'appReducer', reducer: appReducer });
  useInjectReducer({ key: 'dashboardReducer', reducer: dashboardReducer });

  const dispatch = useDispatch();

  useEffect(() => {
    const wsUrl = config.api.wsUrl;
    const newSocket = io(wsUrl, {
      withCredentials: true,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSocket(newSocket);
    return () => newSocket.close();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const setupListeners = async () => {
      if (socket) {
        await socket.emit(
          WEB_SOCKET_MESSAGE.ADVERTISER.CAMPAIGN.ADVERTISER_CREATE_ROOM,
          {
            advertiserId: advertiser.id,
            companyId: advertiser.companyId,
          }
        );
        socket.on(
          WEB_SOCKET_MESSAGE.ADVERTISER.CAMPAIGN.STATUS_CHANGED,
          (message) => {
            console.log('advertiser_campaign_status_changed ', message);
            dispatch(
              wsCampaignStatusChanged(
                message?.data?.status,
                message?.data?.campaignId
              )
            );
          }
        );

        socket.onAny((eventName, ...args) => {
          console.log('eventName', eventName);
          // ...
        });

        socket.on(
          WEB_SOCKET_MESSAGE.ADVERTISER.CAMPAIGN.USER.MEDIA_UPLOADED,
          (message) => {
            console.log('advertiser_campaign_media_uploaded ', message);
            dispatch(
              fetchSingleCampaignGalleryRequest(
                message?.data?.campaignId,
                1,
                15
              )
            );
          }
        );

        return () => {};
      }
    };
    if (advertiser) setupListeners();
    // eslint-disable-next-line
  }, [socket]);

  return <>{children}</>;
};

export default WebsocketProvider;
