import { call, put, takeLatest } from '@redux-saga/core/effects';
import request from 'utils/request';
import { ENDPOINTS } from 'constants/endpoints';
import { handleBadRequestAndUnauthorised } from 'utils/handleBadRequestAndUnauthorised';
import { FETCH_OBJECTIVES_REQUEST } from './constants';
import {
  fetchObjectivesAttempt,
  fetchObjectivesSuccess,
  fetchObjectivesError,
} from './actions';

export function* fetchObjectivesRequest() {
  try {
    yield put(fetchObjectivesAttempt());
    const objectives = yield call(request, {
      url: ENDPOINTS.OBJECTIVES_BY_CATEGORIES,
      method: 'get',
    });
    yield put(fetchObjectivesSuccess(objectives));
  } catch (error) {
    yield put(fetchObjectivesError(error));
    handleBadRequestAndUnauthorised(error.data?.statusCode);
  }
}

export default function* objectiveSaga() {
  yield takeLatest(FETCH_OBJECTIVES_REQUEST, fetchObjectivesRequest);
}
