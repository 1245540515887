import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styles from '../FormFieldStyles.module.scss';
import Error from '../Error';
import Tooltip from 'components/Tooltip';

const InputField = ({
  type,
  name,
  label,
  placeholder,
  required,
  minValue,
  maxValue = 255,
  autoFocus,
  labelHidden,
  component,
  className,
  containerStyle = '',
  inputFieldLarge,
  textarea,
  textareaLarge,
  isDisabled,
  tooltipText,
  inputClassName,
  onBlur,
  onKeyPress,
}) => {
  const { formatMessage } = useIntl();

  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext();

  const [inputValue, setInputValue] = useState('');

  const value = useMemo(
    () =>
      name
        .replace(/\[(\w+)\]/g, '.$1')
        .split('.')
        .reduce((value, index) => value && value[index], values),
    //eslint-disable-next-line
    [name, values]
  );

  useEffect(() => {
    value ? setInputValue(value) : setInputValue('');
    //eslint-disable-next-line
  }, [value]);

  const inputClasses = classNames(
    styles.input,
    {
      [styles.textarea]: textarea,
      [styles.textareaLarge]: textareaLarge,
      [styles.inputFieldLarge]: inputFieldLarge,
      [styles.disabled]: isDisabled,
    },
    inputClassName
  );

  const inputFieldClasses = classNames(
    styles.field,
    {
      [containerStyle]: containerStyle,
      [styles.disabled]: isDisabled,
    },
    className
  );
  const inputRef = useRef();
  return (
    <div className={inputFieldClasses}>
      {!labelHidden && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {required && <span className={styles.requiredSign}>*</span>}
          {tooltipText && <Tooltip label={tooltipText} />}
        </label>
      )}
      <Field
        innerRef={inputRef}
        type={type}
        placeholder={placeholder}
        name={name}
        required={required}
        autoFocus={autoFocus}
        className={inputClasses}
        component={component}
        disabled={isDisabled}
        value={inputValue}
        as={textareaLarge || textarea ? 'textarea' : null}
        onKeyPress={onKeyPress}
        onChange={(e) => {
          handleChange(e);
          setInputValue(e.target.value);
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur();
          }
          handleBlur(e);
          setFieldValue(name, inputValue);
        }}
      />
      {!required && (
        <p onClick={() => inputRef.current.focus()} className={styles.optional}>
          Optional
        </p>
      )}
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
              minValue,
              maxValue,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  labelHidden: PropTypes.bool,
  component: PropTypes.string,
  containerStyle: PropTypes.string,
  inputFieldLarge: PropTypes.bool,
  textarea: PropTypes.bool,
  textareaLarge: PropTypes.bool,
  fileInputField: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};
