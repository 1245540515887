import { defineMessages } from 'react-intl';

export const scope = 'payment_method_modal';

export default defineMessages({
  modalTitle: {
    id: `${scope}.text.modal_title`,
    defaultMessage: 'Add new payment',
  },
  label: {
    id: `${scope}.text.label`,
    defaultMessage: 'Card number',
  },
  cardHolderLabel: {
    id: `${scope}.text.card_holder_label`,
    defaultMessage: 'Card Holder Name',
  },
  cardHolderPlaceholder: {
    id: `${scope}.text.card_holder_placeholder`,
    defaultMessage: 'Enter Full name',
  },
  billingAdressLabel: {
    id: `${scope}.text.billing_adress_label`,
    defaultMessage: 'Billing address',
  },
  billingAdressPlaceholder: {
    id: `${scope}.text.billing_adress_placeholder`,
    defaultMessage: 'Enter Address',
  },
  city: {
    id: `${scope}.text.city`,
    defaultMessage: 'City',
  },
  state: {
    id: `${scope}.text.state`,
    defaultMessage: 'State',
  },
  zipCode: {
    id: `${scope}.text.zip_code`,
    defaultMessage: 'ZIP Code',
  },
  addPayment: {
    id: `${scope}.button.add_payment`,
    defaultMessage: 'Add Payment',
  },
  stripeText: {
    id: `${scope}.text.stripe_text`,
    defaultMessage: 'Powered by Stripe',
  },
});
