export default {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    cdn: process.env.REACT_APP_API_CDN,
    wsUrl: process.env.REACT_APP_WS_URL,
  },
  social: {
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    instagram: {
      appId: process.env.REACT_APP_INSTAGRAM_APP_ID,
      redirectUri: process.env.REACT_APP_INSTAGRAM_REDIRECT_URI,
    },
  },
  websockets: {
    deepstreamUrl: process.env.REACT_APP_DEEPSTREAM_URL,
  },
  sentry: {
    key: process.env.REACT_APP_SENTRY_KEY,
    project: process.env.REACT_APP_SENTRY_PROJECT,
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  googleTagManager: {
    id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    enabled: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENABLED,
  },
  passwordProtection: {
    isEnabled: process.env.REACT_APP_PASSWORD_PROTECTION_ENABLED === 'true',
    phrase: process.env.REACT_APP_PASSWORD_PROTECTION_PHRASE,
  },
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  },
};
