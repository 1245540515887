import React, { useState, createContext, useContext, forwardRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from 'config';
import { Modal } from '@material-ui/core';
import PaymentMethodForm from './PaymentMethodForm';
import styles from './PaymentMethodModal.module.scss';

const stripe = loadStripe(config.stripe.publicKey);

const PaymentMethodModalContext = createContext({
  openModal: () => {},
});

export function PaymentMethodModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onAddPaymentSuccess, setOnAddPaymentSuccess] = useState(null);

  const openModal = (onSuccess = () => {}) => {
    setOnAddPaymentSuccess(() => onSuccess);
    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  return (
    <PaymentMethodModalContext.Provider value={{ openModal }}>
      {children}
      <PaymentMethodModal
        isOpen={isOpen}
        onClose={closeModal}
        onAddPaymentSuccess={onAddPaymentSuccess}
      />
    </PaymentMethodModalContext.Provider>
  );
}

export default function PaymentMethodModal({
  isOpen,
  onClose,
  onAddPaymentSuccess,
}) {
  return (
    <Modal className={styles.modalWrapper} open={!!isOpen} onClose={onClose}>
      <PaymentMethodFormWrapper
        onClose={onClose}
        onAddPaymentSuccess={onAddPaymentSuccess}
      />
    </Modal>
  );
}

const PaymentMethodFormWrapper = forwardRef(
  ({ onClose, onAddPaymentSuccess }, ref) => {
    return (
      <div ref={ref}>
        <Elements stripe={stripe}>
          <PaymentMethodForm
            onSubmit={onClose}
            onAddPaymentSuccess={onAddPaymentSuccess}
          />
        </Elements>
      </div>
    );
  }
);

export const usePaymentMethodModal = () => {
  const { openModal } = useContext(PaymentMethodModalContext);
  return [openModal];
};
