export const SINGLE_CAMPAIGN = '[SingleCampaign]';

export const FETCH_SINGLE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_REQUEST`;
export const FETCH_SINGLE_CAMPAIGN_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_SUCCESS`;
export const REMOVE_SINGLE_CAMPAIGN = `${SINGLE_CAMPAIGN} REMOVE_SINGLE_CAMPAIGN`;
export const FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_GALLERY_REQUEST`;
export const FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_GALLERY_SUCCESS`;
export const FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_REQUEST`;
export const FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_EYVISOR_GALLERY_SUCCESS`;
export const UPDATE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN} UPDATE_CAMPAIGN_REQUEST`;
export const UPDATE_CAMPAIGN_SUCCESS = `${SINGLE_CAMPAIGN} UPDATE_CAMPAIGN_SUCCESS`;
export const UPDATE_CAMPAIGN_ERROR = `${SINGLE_CAMPAIGN} UPDATE_CAMPAIGN_ERROR`;
export const SET_IS_EDIT_MODE = `${SINGLE_CAMPAIGN} SET_IS_EDIT_MODE`;
export const SEND_CUSTOM_NOTIFICATION = `${SINGLE_CAMPAIGN} SEND_CUSTOM_NOTIFICATION`;
export const FETCH_CUSTOM_NOTIFICATIONS_REQUEST = `${SINGLE_CAMPAIGN} FETCH_CUSTOM_NOTIFICATIONS_REQUEST`;
export const FETCH_CUSTOM_NOTIFICATIONS_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_CUSTOM_NOTIFICATIONS_SUCCESS`;
export const CANCEL_SINGLE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN} CANCEL_SINGLE_CAMPAIGN_REQUEST`;
export const CANCEL_SINGLE_CAMPAIGN_SUCCESS = `${SINGLE_CAMPAIGN} CANCEL_SINGLE_CAMPAIGN_SUCCESS`;
export const FETCH_SINGLE_CAMPAIGN_INVITE_LINK_REQUEST = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_INVITE_LINK_REQUEST`;
export const FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_SINGLE_CAMPAIGN_INVITE_LINK_SUCCESS`;
export const FETCH_CAMPAIGN_CONTENT_REQUEST = `${SINGLE_CAMPAIGN} FETCH_CAMPAIGN_CONTENT_REQUEST`;
export const FETCH_CAMPAIGN_CONTENT_SUCCESS = `${SINGLE_CAMPAIGN} FETCH_CAMPAIGN_CONTENT_SUCCESS`;
export const WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS = `${SINGLE_CAMPAIGN} WS_UPDATE_SINGLE_CAMPAIGN_CAMPAIGN_USERS`;
export const WS_CAMPAIGN_STATUS_CHANGED = `${SINGLE_CAMPAIGN} WS_CAMPAIGN_STATUS_CHANGED`;
export const PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN} PUBLISH_PUBLISHABLE_CAMPAIGN_REQUEST`;
export const PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS = `${SINGLE_CAMPAIGN} PUBLISH_PUBLISHABLE_CAMPAIGN_SUCCESS`;

export const CampaignUserJoinedLeftVariables = {
  LeftCampaign: 'left_campaign',
  JoinedCampaign: 'joined_campaign',
};

export const CampaignUserStatusChangedVariables = {
  FinishedCampaign: 'finished_campaign',
  TaskChecked: 'task_checked',
  TaskUnchecked: 'task_unchecked',
  ArrivalTaskChecked: 'arrival_task_checked',
  ArrivalTaskUnchecked: 'arrival_task_unchecked',
  PromotedToSupervisor: 'promoted_to_supervisor',
  DemotedFromSupervisor: 'demoted_from_supervisor',
};
