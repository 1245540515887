import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { fetchFreeSlotsDataRequest } from './actions';
import styles from './Banner.module.scss';
import reducer from './reducer';
import saga from './saga';
import { makeSelectFreeSlotsData } from './selectors';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';
import Icon from 'components/Icon';
const key = 'freeSlots';

const Banner = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  let options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  const dispatch = useDispatch();
  const freeSlots = useSelector(makeSelectFreeSlotsData());

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    dispatch(fetchFreeSlotsDataRequest());
  }, [dispatch]);

  useEffect(() => {
    setTime(new Date(freeSlots?.customersFreeSlotsUpdatedAt));
  }, [freeSlots]);

  return (
    <div className={styles.bannerContainer}>
      <Icon icon={WarningIcon} className={styles.icon} size="md" />
      <div className={styles.textContainer}>
        <p className={styles.title}>
          Only {freeSlots?.customersFreeSlots} new customer slots remain (as of{' '}
          {time.toLocaleDateString() + ' '}
          at
          {' ' + time.toLocaleTimeString('en-us', options)})
        </p>
        <p className={styles.text}>
          Due to the popularity of EyCrowd. We are only able to accept a limited
          number of customers during our early release period.
        </p>
        {freeSlots?.customersFreeSlots === 0 && (
          <p>No more slots available? Click here to get more information!</p>
        )}
      </div>
    </div>
  );
};

export default Banner;
