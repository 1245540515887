import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { ReactComponent as ArrowIcon } from 'assets/images/chevron-down.svg';
import Spinner from 'assets/images/spinner.gif';

function Button({
  className,
  title,
  children,
  disabled,
  buttonReverse,
  isLarge,
  alt,
  altReverse,
  handleClick,
  loadMoreButton,
  loadMoreButtonGallery,
  isLoading,
  toolTip,
  green,
  ...rest
}) {
  const buttonClasses = classNames(
    styles.button,
    {
      [styles.buttonReverse]: buttonReverse,
      [styles.alt]: alt,
      [styles.green]: green,
      [styles.disabled]: disabled,
      [styles.buttonLg]: isLarge,
      [styles.altReverse]: altReverse,
      [styles.loadMoreButton]: loadMoreButton,
      [styles.loadMoreButtonGallery]: loadMoreButtonGallery,
    },
    className
  );

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={buttonClasses}
      title={toolTip}
      {...rest}
    >
      {children} {title}
      {loadMoreButtonGallery && (
        <Icon
          className={styles.loadMoreButtonGalleryIcon}
          icon={ArrowIcon}
          size={'sm14'}
        />
      )}
      {isLoading && <img src={Spinner} alt="Spinner" />}
    </button>
  );
}

export default Button;
