import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/Icon';
import styles from './Tooltip.module.scss';
import classNames from 'classnames';

const Tooltip = ({
  label,
  className,
  tooltipOverrideClassName,
  customContent,
  offset,
}) => {
  return (
    <div
      className={classNames(styles.container, className)}
      data-tip
      data-for={label}
    >
      <Icon icon={InfoIcon} size="sm" className={styles.icon} />
      <ReactTooltip
        offset={offset || { top: -5, right: 140 }}
        arrowColor="transparent"
        className={classNames(styles.tooltipOverride, tooltipOverrideClassName)}
        id={label}
        place="top"
        effect="solid"
      >
        {customContent ? (
          customContent
        ) : (
          <p className={styles.label}>{label}</p>
        )}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
