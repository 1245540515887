import {
  ADVERTISER_CAMPAIGN_GALLERY_ERROR,
  ADVERTISER_CAMPAIGN_GALLERY_REQUEST,
  ADVERTISER_CAMPAIGN_GALLERY_SUCCESS,
  DRAFT_CAMPAIGN_ERROR,
  DRAFT_CAMPAIGN_REQUEST,
  DRAFT_CAMPAIGN_SUCCESS,
  ECW_ADVERTISER_GALLERY_ERROR,
  ECW_ADVERTISER_GALLERY_REQUEST,
  ECW_ADVERTISER_GALLERY_SUCCESS,
  ECW_CREATE_CAMPAIGN_ERROR,
  ECW_CREATE_CAMPAIGN_REQUEST,
  ECW_CREATE_CAMPAIGN_SUCCESS,
  PUBLISH_CAMPAIGN_ERROR,
  PUBLISH_CAMPAIGN_REQUEST,
  PUBLISH_CAMPAIGN_SUCCESS,
  CLEAR_UPLOAD_MODAL,
  FETCH_DUPLICATE_CAMPAIGN_REQUEST,
  FETCH_DUPLICATE_CAMPAIGN_SUCCESS,
  POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS,
  POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR,
  POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
  DELETE_GALLERY_ENTRIES_REQUEST,
  DELETE_GALLERY_ENTRIES_SUCCESS,
  DELETE_GALLERY_ENTRIES_ERROR,
  VERIFY_PROMO_CODE,
} from './constants';

export function postToAdvertiserGalleryRequest(
  { title, meta, tags, type, advertiserId, file },
  setFieldValue,
  onClose
) {
  return {
    type: POST_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
    payload: {
      title,
      meta,
      tags,
      type,
      advertiserId,
      file,
    },
    meta: { setFieldValue, onClose },
  };
}

export function postToAdvertiserGalleryExternalRequest(
  { title, meta, tags, type, advertiserId, path },
  setFieldValue,
  onClose
) {
  return {
    type: POST_EXTERNAL_MEDIA_TO_ADVERTISER_GALLERY_REQUEST,
    payload: {
      title,
      meta,
      tags,
      type,
      advertiserId,
      path,
    },
    meta: { setFieldValue, onClose },
  };
}

export function postToAdvertiserGallerySuccess(data) {
  return {
    type: POST_MEDIA_TO_ADVERTISER_GALLERY_SUCCESS,
    payload: data,
  };
}

export function postToAdvertiserGalleryError() {
  return {
    type: POST_MEDIA_TO_ADVERTISER_GALLERY_ERROR,
  };
}

export function createCampaign(campaignData) {
  return {
    type: ECW_CREATE_CAMPAIGN_REQUEST,
    campaignData,
  };
}

export function createCampaignSuccess(campaignData) {
  return {
    type: ECW_CREATE_CAMPAIGN_SUCCESS,
    campaignData,
  };
}

export function createCampaignError() {
  return {
    type: ECW_CREATE_CAMPAIGN_ERROR,
  };
}

export function advertiserGalleryRequest(advertiserId) {
  return {
    type: ECW_ADVERTISER_GALLERY_REQUEST,
    advertiserId,
  };
}

export function advertiserGallerySuccess(advertiserGallery) {
  return {
    type: ECW_ADVERTISER_GALLERY_SUCCESS,
    advertiserGallery,
  };
}

export function advertiserGalleryError() {
  return {
    type: ECW_ADVERTISER_GALLERY_ERROR,
  };
}

export function advertiserCampaignGalleryRequest(advertiserId) {
  return {
    type: ADVERTISER_CAMPAIGN_GALLERY_REQUEST,
    advertiserId,
  };
}

export function advertiserCampaignGallerySuccess(advertiserCampaignGallery) {
  return {
    type: ADVERTISER_CAMPAIGN_GALLERY_SUCCESS,
    advertiserCampaignGallery,
  };
}

export function advertiserCampaignGalleryError() {
  return {
    type: ADVERTISER_CAMPAIGN_GALLERY_ERROR,
  };
}

export function deleteGalleryEntriesRequest(
  galleryEntryIds,
  setSelectedMedias
) {
  return {
    type: DELETE_GALLERY_ENTRIES_REQUEST,
    galleryEntryIds,
    setSelectedMedias,
  };
}

export function deleteGalleryEntriesSuccess(galleryEntryIds) {
  return {
    type: DELETE_GALLERY_ENTRIES_SUCCESS,
    galleryEntryIds,
  };
}

export function deleteGalleryEntriesError() {
  return {
    type: DELETE_GALLERY_ENTRIES_ERROR,
  };
}

export function draftCampaignRequest(campaignData) {
  return {
    type: DRAFT_CAMPAIGN_REQUEST,
    campaignData,
  };
}

export function draftCampaignSuccess(campaign) {
  return {
    type: DRAFT_CAMPAIGN_SUCCESS,
    campaign,
  };
}

export function draftCampaignError() {
  return {
    type: DRAFT_CAMPAIGN_ERROR,
  };
}

export function publishCampaignRequest(campaignData) {
  return {
    type: PUBLISH_CAMPAIGN_REQUEST,
    campaignData,
  };
}

export function publishCampaignSuccess(campaign) {
  return {
    type: PUBLISH_CAMPAIGN_SUCCESS,
    campaign,
  };
}

export function publishCampaignError() {
  return {
    type: PUBLISH_CAMPAIGN_ERROR,
  };
}

export function clearUploadModal() {
  return {
    type: CLEAR_UPLOAD_MODAL,
  };
}

export function fetchDuplicateCampaignRequest(id) {
  return {
    type: FETCH_DUPLICATE_CAMPAIGN_REQUEST,
    id,
  };
}

export function fetchDuplicateCampaignSuccess(duplicateCampaign) {
  return {
    type: FETCH_DUPLICATE_CAMPAIGN_SUCCESS,
    duplicateCampaign,
  };
}

export function verifyPromoCode(promoCode, onSuccess) {
  return {
    type: VERIFY_PROMO_CODE,
    promoCode,
    meta: { onSuccess },
  };
}
