import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './DashboardLayout.module.scss';
import Icon from '../../Icon';
import { ReactComponent as HomeIcon } from 'assets/images/home.svg';
import { ReactComponent as PlusSign } from 'assets/images/add-black.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as HelpIcon } from 'assets/images/help.svg';
import dashboardLogo from 'assets/images/logo-black-2.png';
import {
  DASHBOARD_CREATE_CAMPAIGN,
  DASHBOARD,
  ADVERTISER_SETTINGS,
  NEED_HELP,
} from 'routes';

const SIDE_BAR_ITEMS = [
  {
    label: 'Create Campaign',
    route: DASHBOARD_CREATE_CAMPAIGN,
    icon: PlusSign,
  },
  {
    label: 'Dashboard',
    route: DASHBOARD,
    icon: HomeIcon,
  },
  {
    label: 'Settings',
    route: ADVERTISER_SETTINGS,
    icon: SettingsIcon,
  },
];

const SIDE_BAR_NEED_HELP_ITEM = [
  {
    label: 'Need help?',
    route: NEED_HELP,
    icon: HelpIcon,
  },
];

const DashboardSideBar = () => (
  <div className={styles.sideBar}>
    <div className={styles.logoWrapper}>
      <img src={dashboardLogo} alt="EyCrowd logo" style={{ height: '100%' }} />
    </div>
    <ul className={styles.spaceBetween}>
      <div>
        {SIDE_BAR_ITEMS.map((item) => (
          <li className={styles.listItem} key={item.label}>
            <NavLink
              to={item.route}
              activeClassName={styles.linkActive}
              className={styles.link}
              exact
            >
              <span className={styles.iconWrapper}>
                <Icon icon={item.icon} />
              </span>
              {item.label}
            </NavLink>
          </li>
        ))}
      </div>
      <div>
        {SIDE_BAR_NEED_HELP_ITEM.map((item) => (
          <li className={styles.listItem} key={item.label}>
            <NavLink
              to={item.route}
              activeClassName={styles.linkActive}
              className={styles.link}
              exact
            >
              <span className={styles.iconWrapper}>
                <Icon icon={item.icon} />
              </span>
              {item.label}
            </NavLink>
          </li>
        ))}
      </div>
    </ul>
  </div>
);

export default DashboardSideBar;
